import {Menu, Transition} from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  Square2StackIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index.js";
import React, {Fragment, useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DashboardContext from "../Contexts/DashboardContext";
import {clearSpace, saveSpaceThunk, updateSpaceThunk} from "../reducers/spacesSlice";
import {classNames} from "../utils/classes";

export const SORT_OPERATIONS = {
  latestMessageDesc: {label: 'Newest messages', desc: true, key: 'latestMessage'},
  latestMessageAsc: {label: 'Oldest messages', desc: false, key: 'latestMessage'},
  insertedAtDesc: {label: 'Oldest channels', desc: true, key: 'insertedAt'},
  insertedAtAsc: {label: 'Newest channels', desc: false, key: 'insertedAt'},
  nameDesc: {label: 'By name, A-Z', desc: true, key: 'name'},
  nameAsc: {label: 'By name, Z-A', desc: false, key: 'name'},
}

export default function Header({setFilter, setSort}) {
  const [spaceIsEditable, setSpaceEditable] = useState(false);
  const activeSpace = useSelector((state) => state.spaces.activeSpace);
  const {toggleShowSidebar} = useContext(DashboardContext);
  const dispatch = useDispatch();

  function renderFilterChannelNameInput(classes) {
    return (
      <input
        type="search"
        name="search"
        id="search"
        onChange={({target: {value}}) => setFilter(value)}
        className={classNames(classes, "shrink bg-slate-700 mx-auto block rounded-md border-0 py-1 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6")}
        placeholder="Filter by channel name..."
      />
    )
  }

  async function upsertSpaceName({target}) {
    const {innerText: newName} = target;

    if (activeSpace.id) {
      const {error} = await dispatch(updateSpaceThunk({...activeSpace, newName}));
      if (error) target.innerText = activeSpace.name;
    } else {
      dispatch(saveSpaceThunk({...activeSpace, name: newName}));
    }
  }

  return (
    <>
      <header className="h-16 bg-gray-800 sticky top-0 z-50 border-b border-white/5 px-2 py-2 sm:px-6 sm:py-3 lg:px-4 flex items-center">
        <div className="flex items-center justify-between w-full">
          <div className="grow flex items-center space-x-1">
            <button onClick={() => toggleShowSidebar()} type="button" className="-m-4 p-2.5 mr-0.5 text-white xl:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-7 w-7" aria-hidden="true" />
            </button>
            {
              activeSpace.id || activeSpace.channels.length ? (
                <>
                  <Square2StackIcon className="h-5 w-5" />
                  <h1
                    onDoubleClick={() => setSpaceEditable(true)}
                    onBlur={upsertSpaceName}
                    contentEditable={spaceIsEditable}
                    suppressContentEditableWarning={true}
                    className={classNames(!activeSpace.name ? 'italic font-normal' : 'font-semibold', 'text-base leading-7 text-white')}>
                    {activeSpace.name || 'Untitled Space'}
                  </h1>
                  <Menu as="div" className="relative grow flex">
                    <Menu.Button className="flex items-center gap-x-1 ml-1 text-sm font-medium leading-6 text-white border-0">
                      <ChevronDownIcon className="text-gray-400 h-4 w-4 hover:text-gray-200 transition cursor-pointer" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="divide-gray-600 divide-y absolute left-0 z-10 mt-7 w-48 origin-top-right rounded-md bg-gray-700 text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item as="div" className="p-2 flex items-center space-x-2 cursor-pointer" onClick={() => dispatch(clearSpace())}>
                          <XMarkIcon className="h-5 w-5" />
                          <div className="font-semibold text-sm">Close Space</div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </>
              ) : null
            }
          </div>

          {renderFilterChannelNameInput('hidden md:block w-1/2')}

          <Menu as="div" className="relative grow flex justify-end">
            <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-white border-0">
              Sort
              <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-7 w-48 origin-top-right rounded-md bg-gray-700 text-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {
                  Object.keys(SORT_OPERATIONS).map((opKey) => {
                    return (
                      <Menu.Item key={opKey}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-500' : '',
                              'block px-3 py-1 text-sm leading-6 text-white'
                            )}
                            onClick={() => setSort(opKey)}
                          >
                            {SORT_OPERATIONS[opKey].label}
                          </a>
                        )}
                      </Menu.Item>
                    )
                  })
                }
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
      {renderFilterChannelNameInput('md:hidden w-[97%] mb-4')}
    </>
  );
}
