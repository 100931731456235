import React from "react";
import Tooltip from "@tippyjs/react";

export default function FuseLogoText() {
  return (
    <Tooltip content={(
      <div>
        <div className="mb-2">fuse is still in beta.</div>
        <div>It's feature are intended for evaluation</div>
      </div>
    )}>
      <div className="relative">
        <span className="font-[Comfortaa] text-[1.7em] ml-2">fuse</span>
        <span className="absolute top-0 ml-1 rounded-full font-semibold bg-purple-500 scale-95 -mb-2 px-1.5 py-0.5 text-xs inline-block">βeta</span>
      </div>
    </Tooltip>
  );
}
