export default function SpaceBeaconPin({onClick}) {
  return (
    <div onClick={onClick} className="w-3 h-3 flex items-center justify-center">
      <div className="w-2 h-2 rounded-full bg-blue-500 flex items-center justify-center">
        <div className="animate-ping w-2 h-2 rounded-full bg-green-500">
        </div>
      </div>
    </div>
  )
}
