import {Transition} from "@headlessui/react";
import {Fragment} from "react";
import {useSelector} from "react-redux";
import ChannelTag from "../Channel/ChannelTag";

export default function ModalTagFilter({clearTags, open, filteredTags = {}, toggleTag}) {
  const tagsById = useSelector(state => state.tags);
  const tags = Object.values(tagsById);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-500"
        enterFrom="h-0"
        enterTo="h-48"
        leave="ease-in duration-200"
        leaveFrom="h-48"
        leaveTo="h-0"
      >
        <div className="overflow-y-auto border-y border-gray-900 bg-gray-700">
          <h3 className="sticky top-0 z-10 bg-gray-700 text-base p-3 font-semibold leading-6 text-gray-100 flex justify-between items-center">
            <div>Filter by tag</div>
            <div className="cursor-pointer" onClick={clearTags}>Clear</div>
          </h3>
          <div className="relative max-h-48 h-full px-3 transform text-white text-left transition-all w-full">
            <div className="mt-2 font-semibold space-y-4">
              <div className="flex-wrap gap-2 flex items-center relative pb-5">
                { !tags.length ? (<div className="w-full font-normal text-center italic mb-10 mt-7">You haven't created any tags yet.</div>) : null }
                {
                  tags.map((tag) => {
                    return (
                      <div key={`tag_modal_${tag.id}`} className="cursor-pointer">
                        <ChannelTag fill={!!filteredTags[tag.id]} onClick={() => toggleTag(tag)} tagId={tag.id} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  )
}
