import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {
  ChevronDownIcon,
  SpeakerXMarkIcon,
  TagIcon,
  TvIcon
} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from '@tippyjs/react';
import {toast} from "react-toastify";
import ModalTagFilter from "../modals/ModalTagFilter";
import {
  launchSpace,
  removeChannelFromActiveSpace,
  removeChannelFromSpace,
  updateSpaceThunk
} from "../reducers/spacesSlice";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import SortableTreeSpaceContext from "./SortableTreeSpaceContext";
import {classNames} from "../utils/classes";
import SortableTreeItem from "./SortableTreeItem";
import {useEffect, useMemo, useRef, useState} from "react";
import SpaceBeaconPin from "./SpaceBeaconPin";

export default function SortableTree({id, activeId}) {
  const [filterByName, setFilterByName] = useState('');
  const [filterByTagIds, setFilterByTagIds] = useState({});
  const channelsById = useSelector((state) => state.channels);
  const {spaces} = useSelector(state => state.spaces);
  const [showTagFilterModal, setShowTagFilterModal] = useState(false);
  const [filteredChannelsById, setFilteredChannelsById] = useState({});
  const [minimizedSpaces, setMinimizedSpaces] = useState(null);
  const [showMutedChannelsBySpaceId, setShowMutedChannelsBySpaceId] = useState({});
  const [changeChannelSpace, setChangeChannelSpace] = useState(null);
  const tagFilterButtonRef = useRef(null);
  const channels = useSelector(state => state.channels);
  const {activeSpace} = useSelector(state => state.spaces);
  const dispatch = useDispatch();

  const isFilteringByTags = !!Object.keys(filterByTagIds).length;
  const isFilteringAnything = isFilteringByTags || filterByName.length;

  const channelsByIdBySpaceId = useMemo(() => {
    return spaces.reduce((acc, space) => {
      acc[space.id] = space.channels.reduce((acc, chId) => {
        acc[chId] = true;
        return acc;
      }, {});

      return acc;
    }, {});
  }, [spaces, channels]);

  useEffect(() => {
    const filteredChannels = Object.values(channels)
      .filter((channel) => isFilteringByTags ? channel.tags.some((tag) => !!filterByTagIds[tag.id]) : true)
      .filter((channel) => channel.slug.toLowerCase().includes(filterByName.toLowerCase()))
      .reduce((acc, channel) => {
        acc[channel.id] = channel;
        return acc;
      }, {});


    setFilteredChannelsById(filteredChannels);
  }, [filterByTagIds, channels, filterByName]);

  useEffect(() => {
    if (!spaces.length || minimizedSpaces) return;

    const defaultMinimizedSpaces = spaces
      .reduce((acc, {id, managed, defaultMinimized, channels}) => {
        acc[id] = defaultMinimized || (managed && !channels.length) || false;
        return acc;
      }, {});

    setMinimizedSpaces(defaultMinimizedSpaces);
  }, [spaces]);

  function toggleMutedChannelsBySpaceId(spaceId) {
    const showingMutedChannels = showMutedChannelsBySpaceId[spaceId];
    setShowMutedChannelsBySpaceId({...showMutedChannelsBySpaceId, [spaceId]: !showingMutedChannels})
  }

  function applyUserFilters(channelId) {
    return isFilteringByTags || filterByName.length ? !!filteredChannelsById[channelId] : true;
  }

  async function swapChannel(toSpace) {
    const {id: toSpaceId, name: toSpaceName} = toSpace;
    const {fromSpaceId, channelId} = changeChannelSpace;
    const fromSpace = spaces.find(space => space.id === changeChannelSpace.fromSpaceId);

    try {
      if (!fromSpace) {
        toast.error("We could not find the originating space. Try again.");
        return;
      }

      if (!fromSpace.managed) {
        dispatch(removeChannelFromActiveSpace(channelId));
        dispatch(removeChannelFromSpace({channelId, spaceId: fromSpaceId}));
      }

      await dispatch(updateSpaceThunk({...toSpace, channels: [channelId, ...toSpace.channels]}));

      if (activeSpace.id === toSpaceId) {
        dispatch(launchSpace(toSpaceId));
      }
    } finally {
      toast.dismiss(changeChannelSpace._toastId);
      setChangeChannelSpace(null);
      toast.success(`Channel moved from ${fromSpace.name} to ${toSpaceName}.`, DEFAULT_TOAST_CONFIG);
    }
  }

  if (!channelsById || !minimizedSpaces) return null;

  return (
    <SortableContext
      id={id}
      items={spaces}
      strategy={verticalListSortingStrategy}
    >
      <div className="sticky top-32 z-20 mb-3 bg-gray-800 pb-1">
        <div className="flex items-center space-x-1 px-2">
          <div className='grow-1 w-full'>
            <label htmlFor="search" className="sr-only">
              Filter by channel name
            </label>
            <input
              type="search"
              name="search"
              id="search"
              onChange={({target: {value}}) => setFilterByName(value)}
              className="bg-slate-700 px-1.5 block w-full rounded-md border-0 py-0.5 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
              placeholder="Filter by channel name..."
            />
          </div>
          <button
            ref={tagFilterButtonRef}
            onClick={() => setShowTagFilterModal(!showTagFilterModal)}
            className={classNames(Object.keys(filterByTagIds).length ? 'bg-orange-500' : 'bg-gray-800', 'flex items-center hover:ring-2 ring-gray-600 rounded-md p-1 shrink-1')}
          >
            <TagIcon className="h-5 w-5 text-gray-300" />
            <ChevronDownIcon className={classNames(showTagFilterModal ? 'rotate-180' : '', "transition ml-0.5 h-3 w-3")} />
          </button>
        </div>
        <div className="bg-gray-600 w-full shadow-inner shadow-sm mt-2">
          <ModalTagFilter
            parentRef={tagFilterButtonRef.current}
            open={showTagFilterModal}
            clearTags={() => setFilterByTagIds({})}
            filteredTags={filterByTagIds}
            onClose={() => setShowTagFilterModal(false)}
            toggleTag={(tag) => {
              if (filterByTagIds[tag.id]) {
                const newFilterByTags = {...filterByTagIds};
                delete newFilterByTags[tag.id];
                setFilterByTagIds(newFilterByTags);
                return;
              }

              setFilterByTagIds({...filterByTagIds, [tag.id]: filterByTagIds[tag.id] ? null : tag})
            }}
          />
        </div>
      </div>


      <ul role="list" className="-ml-2 space-y-1 px-1">
        <>
          {
            spaces.map((space) => {
              const {id: spaceId, name, managed: isManaged} = space;

              const unmutedChannelIds = isManaged ? space.channels : space.channels.filter((chId) => !channels[chId]?.muted);

              const channelIds = unmutedChannelIds.filter(applyUserFilters);
              const mutedChannelIds = space.channels.filter((chId) => channels[chId]?.muted).filter(applyUserFilters);

              const isMini = isFilteringByTags ? false : minimizedSpaces[spaceId];
              const canMoveToSpace = changeChannelSpace && !isManaged && !channelsByIdBySpaceId[spaceId][changeChannelSpace.channelId] && space.id !== changeChannelSpace.fromSpaceId;

              return (
                <SortableTreeSpaceContext.Provider
                  key={`tree_item_${spaceId}`}
                  value={{
                    changeChannelSpace,
                    setChangeChannelSpace,
                    toggleMutedChannelsBySpaceId,
                    showMutedChannelsBySpaceId,
                    hasMutedChannelsToShow: mutedChannelIds.length
                  }}
                >
                  <div className="ml-1">
                    <SortableTreeItem
                      isSpace
                      title={name}
                      activeId={activeId}
                      data={{parent: space}}
                      id={`tree:parent:${spaceId}`}
                      minimized={isMini}
                      minimizeSpace={() => {
                        if (isFilteringAnything) return;
                        const isMinimized = !minimizedSpaces[spaceId];
                        setMinimizedSpaces({...(minimizedSpaces || {}), [spaceId]: isMinimized});
                        dispatch(updateSpaceThunk({...space, minimized: isMinimized}));
                      }}
                      badges={(
                        <div className="flex items-center">
                          {
                            canMoveToSpace ? (
                              <Tooltip content="Click to move channel to this space">
                                <SpaceBeaconPin onClick={() => swapChannel(space)} />
                              </Tooltip>
                            ) : null
                          }
                          <div className="flex items-center space-x-0.5 mr-1">
                            {
                              !isManaged ? (
                                <>
                                  {
                                    isMini ? (
                                      <Tooltip content={`${unmutedChannelIds.length} active channel${unmutedChannelIds.length > 1 ? 's' : ''} in this Space`}>
                                        <span
                                          className="ml-2 rounded-full flex items-center space-x-1 text-xs text-white bg-gray-800 border-2 border-gray-600 py-0.5 px-1.5">
                                          <TvIcon className="h-3 w-3"/>
                                          <span>{unmutedChannelIds.length}</span>
                                        </span>
                                      </Tooltip>
                                    ) : null
                                  }
                                  {
                                    mutedChannelIds.length ? (
                                      <Tooltip content={`${mutedChannelIds.length} muted channel${mutedChannelIds.length > 1 ? 's' : ''} in this Space`}>
                                        <span
                                          onClick={() => toggleMutedChannelsBySpaceId(spaceId)}
                                          className="cursor-pointer rounded-full flex items-center space-x-1 text-xs text-white bg-gray-800 border-2 border-gray-600 py-0.5 px-1.5">
                                          <SpeakerXMarkIcon className="h-3 w-3"/>
                                          <span>{mutedChannelIds.length}</span>
                                        </span>
                                      </Tooltip>
                                    ) : null
                                  }
                                </>
                              ) : null
                            }
                          </div>
                        </div>
                      )}
                    />
                    <ul className={classNames(isMini ? 'hidden' : '')}>
                      {
                        !channelIds.length ? (
                          <div className="pl-9 text-sm italic text-gray-400 mt-1 mb-3">No channels here...</div>
                        ) : null
                      }
                      {
                        channelIds.map((channelId) => {
                          const channel = channelsById[channelId];

                          if (!channel) return;

                          return (
                            <div className="ml-5 mt-1" key={`tree_sub_item_${spaceId}_${channelId}`}>
                              <SortableTreeItem
                                isChannel
                                title={name}
                                data={{channel}}
                                spaceId={spaceId}
                                activeId={activeId}
                                id={`tree:sub:${spaceId}:${channelId}`}
                              />
                            </div>
                          )
                        })
                      }
                      { mutedChannelIds.length && showMutedChannelsBySpaceId[spaceId] ? (
                        <div className="w-full mt-5">
                          <div className="relative ml-2 pl-2">
                            <div className="h-[2px] bg-gradient-to-r from-transparent from-20% via-gray-600 to-gray-600"></div>
                            <span className="bg-gray-800 text-xs text-gray-400 bg-gradient absolute mx-auto -top-2.5 pt-[1px] inline-block w-auto">Muted Channels</span>
                            {
                              mutedChannelIds.map((channelId) => {
                                const channel = channelsById[channelId];

                                return (
                                  <div className="mt-2 my-2 ml-1" key={`tree_sub_item_${spaceId}_${channelId}`}>
                                    <SortableTreeItem
                                      isChannel
                                      spaceId={spaceId}
                                      activeId={activeId}
                                      title={name}
                                      id={`tree:sub:${spaceId}:${channelId}`}
                                      data={{channel}}
                                    />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      ) : null
                      }
                    </ul>
                  </div>
                </SortableTreeSpaceContext.Provider>
              )
            })
          }
        </>
      </ul>
    </SortableContext>
  );
}
