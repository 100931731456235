import {TvIcon} from "@heroicons/react/24/outline";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {useCopyToClipboard, useInterval, useLocalStorage} from "react-use";
import ModalArchiveChannel from "./Channel/ModalArchiveChannel";
import SettingsModal from "./Channel/SettingsModal";
import EventManager from "./EventManager";
import useSocket, {useSocketEvent} from "./hooks/useSocket";
import ModalNewSpace from "./modals/ModalNewSpace";
import {setChannels, updateChannel} from "./reducers/channelsSlice";
import {MODAL_IDS, showModal} from "./reducers/modalsSlice";
import {setSpaces} from "./reducers/spacesSlice";
import {setTags} from "./reducers/tagsSlice";
import {setUser} from "./reducers/userSlice";
import SlideoverNewChannel from "./Slideovers/SlideoverNewChannel";
import ChannelsContext from './SortableChannelGrid/ChannelsContext';
import DashboardContext from './Contexts/DashboardContext';

import Sidebar from './Dashboard/Sidebar';
import SortableChannelGrid from "./SortableChannelGrid/SortableChannelGrid";
import UserContext from "./Contexts/UserContext";
import DndContext from './SortableChannelGrid/DndContext';

import {TagsManager} from "./Tree/ChannelTagsManager";
import {classNames} from "./utils/classes";
import {notify, requestNotificationPermission} from "./utils/Notify";
import {DEFAULT_TOAST_CONFIG} from "./utils/toast";
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';

function useOnlineBeacon() {
  const [socket] = useSocket();
  useInterval(notifyIsOnline, 20000);
  useEffect(() => {
    if(socket) notifyIsOnline();
  }, [socket]);

  function notifyIsOnline() {
    socket.emit('user:online');
  }
}

export default function Dashboard() {
  useOnlineBeacon();
  const [socket, connected] = useSocket();
  const [showSidebar, setShowSidebar] = useState(false)
  const [showNewRoomModal, setShowNewRoomModal] = useState(false);
  const [showChannelSettingsModal, setShowChannelSettingsModal] = useState(null);
  const [showArchiveChannelModal, setShowArchiveChannelModal] = useState(null);
  const [draggableActiveId, setDraggableActiveId] = useState(null);
  const [, copyToClipboard] = useCopyToClipboard();
  const [socketDebug] = useLocalStorage('socketDebug', false);

  const me = useSelector((state) => state.user);
  const channelsById = useSelector((state) => state.channels);

  const dispatch = useDispatch();

  async function getMe() {
    return new Promise((resolve) => {
      socket.emit('me', null, (me) => {
        if ('userGuiding' in window) window.userGuiding.identify(me.user.id);

        dispatch(setUser(me));
        dispatch(setSpaces(me.user.state.spaces));
        dispatch(setChannels(me.user.channels));
        dispatch(setTags(me.user.allTags));
        resolve();
      });
    })
  }

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    if (connected && socket) {
      getMe();
    }
  }, [socket, connected]);

  useSocketEvent('channels:invitation', ({channel}) => {
    getMe().then(() => {
      toast.info(
        (
          <div className="space-y-2">
            <p className="text-slate-200 leading-tight text-sm">You have been added to a new channel</p>
            <strong className="block text-slate-200 text-sm">{channel.slug}</strong>
            <p className="text-xs text-slate-300">Click for options</p>
          </div>
        ),
        {
          ...DEFAULT_TOAST_CONFIG,
          autoClose: false,
          icon: (<TvIcon className="h-5 w-5 text-orange-500" />),
          onClick: () => {
            setShowSidebar(true);
            dispatch(showModal({
              modalId: MODAL_IDS.MODAL_CONFIRM_APPEND,
              data: {channelId: channel.id, spaceId: '__all_channels'},
            }));
          }
      });
    });
  });

  useSocketEvent(`updated_user_state`, ({spaces, channels}) => {
    console.log('updated_user_state', spaces, channels);
    if (spaces) dispatch(setSpaces(spaces));
    if (channels) dispatch(setChannels(channels));
  });

  useSocketEvent('channel:updated', ({updatedChannel}) => {
    dispatch(updateChannel({channelId: Number.parseInt(updatedChannel.id), updatedChannel}));
  });

  useSocketEvent('new_channel', getMe);

  function toggleShowSidebar() {
    setShowSidebar(!showSidebar);
  }

  return (
    <DashboardContext.Provider value={{
      setShowNewRoomModal,
      showNewRoomModal,
      draggableActiveId,
      setShowChannelSettingsModal,
      setShowArchiveChannelModal,
      showSidebar,
      toggleShowSidebar,
    }}>
      <EventManager />
      <UserContext.Provider value={me}>
        <ChannelsContext.Provider value={{channelsById}}>
          <DndContext setActiveId={setDraggableActiveId}>
            <div>
              <Sidebar />
              <div className="xl:pl-[20%]">
                <main className="z-30 fixed bg-transparent overflow-y-auto sm:pl-0.5 h-full w-full xl:pr-[20%]">
                  <div className={classNames(socketDebug ? 'mb-20' : 'null', "grow h-full")}>
                    <div className="flex justify-between">
                      <div className="w-full">
                        <SortableChannelGrid activeId={draggableActiveId} />
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </DndContext>
          {
            socketDebug ? (
              <div className="mt-10 w-full text-white bottom-0 left-0 fixed text-xs z-[99999] pointer-events-none">
                <div className="bg-black w-96 mx-auto text-center pointer-events-auto p-1 rounded-t-md">
                  <div>Server: {me?.pmId} @ {me?.server}</div>
                  <div>Socket: {me?.socketId}</div>
                  <div onClick={() => copyToClipboard(me?.user?.id)}>User: {me?.user?.id}</div>
                  <div className="flex justify-between items-center">
                    <div onClick={() => {
                      methodThatDoesNotExist();
                    }}>Create FE Error</div>
                    <div onClick={() => {
                      socket.emit('debug:create_error', {err: 'My manual BE error'});
                    }}>Create BE Error</div>
                    <div onClick={() => notify({title: 'Test Notification', body: 'test test test', force: true})}>
                      Notify
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          <SettingsModal open={!!showChannelSettingsModal} channelId={showChannelSettingsModal} />
          <ModalNewSpace />
          <ModalArchiveChannel />
          <TagsManager />
          <SlideoverNewChannel open={showNewRoomModal} setOpen={setShowNewRoomModal} />
          <ToastContainer
            bodyClassName="bg-slate-900"
            toastClassName="p-2 bg-slate-900"
            progressClassName="bg-orange-500"
            position="bottom-center"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="dark"
          />
        </ChannelsContext.Provider>
      </UserContext.Provider>
    </DashboardContext.Provider>
  )
}
