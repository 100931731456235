import {arrayMove} from "@dnd-kit/sortable";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import connect from '../socket/socket';
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

const localActiveSpace = window.localStorage.getItem('activeSpace');

const initialState = {
  spaces: [],
  activeSpace: localActiveSpace ? JSON.parse(localActiveSpace) : {id: null, name: null, channels: []},
};

export const saveSpaceThunk = createAsyncThunk(
  'spaces/saveSpace',
  async (activeSpace, thunkAPI) => {
    const socket = connect();
    const response = await socket.emitWithAck('user:space:new', {newSpace: activeSpace});
    thunkAPI.dispatch(setSpaces(response.updatedUser.state.spaces));
    thunkAPI.dispatch(launchSpace(response.newSpaceId));
    return response;
  }
);

export const removeSpaceThunk = createAsyncThunk(
  'spaces/removeSpace',
  async (space, thunkAPI) => {
    const socket = connect();
    const response = await socket.emitWithAck('user:space:remove', {space});
    thunkAPI.dispatch(clearSpace());
    return response;
  }
);

export const toggleMuteSpaceChannelThunk = createAsyncThunk(
  'spaces/unmuteSpaceChannel',
  async ({channelId, spaceId}) => {
    const socket = connect();

    await socket.emitWithAck('user:state:toggle_mute', {
      channelId,
      spaceId,
    });
  }
);

export const updateSpaceThunk = createAsyncThunk(
  'spaces/updateSpace',
  async ({id: targetSpaceId, newName, channels, minimized}, thunkAPI) => {
    if (newName && newName.length < 2) {
      return Promise.reject(new Error('A Space name must be more than 2 characters.'));
    }

    const socket = connect();
    const {spaces, activeSpace} = thunkAPI.getState().spaces;

    const tempSpaces = [...spaces];
    const spaceIdx = tempSpaces.findIndex(({id}) => id === targetSpaceId);

    const tempSpace = tempSpaces[spaceIdx];

    newName = newName || tempSpace.name;
    minimized = minimized || tempSpace.minimized || false;

    tempSpaces[spaceIdx] = {
      ...tempSpace,
      name: newName,
      channels,
      defaultMinimized: minimized,
    };

    console.log('Updating user state', tempSpaces[spaceIdx]);

    const response = await socket.emitWithAck('update_user_state', {
      partialState: {
        spaces: tempSpaces,
      }
    });

    if (targetSpaceId === activeSpace.id) thunkAPI.dispatch(changeSpaceName(newName));

    return response;
  }
);

const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(saveSpaceThunk.fulfilled, (state, action) => {
      toast.success(`"${action.meta.arg.name}" has been created!`, DEFAULT_TOAST_CONFIG);
    });

    builder.addCase(updateSpaceThunk.fulfilled, (state, action) => {
      const newName = action.meta.arg.newName;
      if (newName) toast.info(`"${newName}" has been changed.`, DEFAULT_TOAST_CONFIG);
    });

    builder.addCase(updateSpaceThunk.rejected, (state, action) => {
      console.log('Could not update space', action.error.message);
      toast.error(action.error.message, DEFAULT_TOAST_CONFIG);
    });
  },
  reducers: {
    setSwapChannelsInSpace(state, action) {
      const {from, to} = action.payload;
      const channels = state.activeSpace.channels;

      const oldIndex = channels.findIndex((id) => `grid:${id}` === from);
      const newIndex = channels.findIndex((id) => `grid:${id}` === to);

      state.activeSpace.channels = arrayMove(channels, oldIndex, newIndex);
    },
    setSpaces(state, action) {
      state.spaces = action.payload;
    },
    changeSpaceName(state, action) {
      if (!state.activeSpace.id) return state;
      state.activeSpace.name = action.payload;
    },
    sortActiveSpace(state, action) {
      state.activeSpace.channels = action.payload;
    },
    removeChannelsFromActiveSpace(state, action) {
      const channelIds = action.payload;
      const channelIdsById = channelIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});

      state.activeSpace.channels = state.activeSpace.channels.filter(chId => !channelIdsById[chId]);
    },
    removeChannelFromActiveSpace(state, action) {
      const chIdx = state.activeSpace.channels.findIndex((channelId) => channelId === action.payload);
      if (chIdx > -1) state.activeSpace.channels.splice(chIdx, 1);
    },
    removeChannelFromSpace(state, action) {
      const spIdx = state.spaces.findIndex(({id}) => id === action.payload.spaceId);
      if (spIdx < 0) return;

      const chIdx = state.spaces[spIdx].channels.findIndex((channelId) => channelId === action.payload.channelId);
      if (chIdx > -1) state.spaces[spIdx].channels.splice(chIdx, 1);
    },
    addChannelToActiveSpace(state, action) {
      const channelId = parseInt(action.payload);

      if (state.activeSpace.channels.includes(channelId)) return;

      state.activeSpace.channels.unshift(channelId);
    },
    clearSpaceWithNewChannel(state, action) {
      console.log('clearSpaceWithNewChannel', action.payload);
      state.activeSpace.id = null;
      state.activeSpace.name = null;
      state.activeSpace.channels = [action.payload];
    },
    clearSpace(state) {
      state.activeSpace.id = null;
      state.activeSpace.name = null;
      state.activeSpace.channels = [];
    },
    appendChannelToActiveSpace(state, action) {
      const channelId = parseInt(action.payload);

      if (state.activeSpace.channels.includes(channelId)) return;

      state.activeSpace.id = null;
      state.activeSpace.name = null;
      state.activeSpace.channels.unshift(channelId);
    },
    launchChannels(state, action) {
      state.activeSpace.id = null;
      state.activeSpace.name = null;
      state.activeSpace.channels = action.payload.map((id) => Number.parseInt(id));
    },
    launchSpace(state, action) {
      state.activeSpace = state.spaces.find(({id}) => id === action.payload);
    },
  }
});

export const {
  setSpaces,
  clearSpace,
  launchSpace,
  launchChannels,
  changeSpaceName,
  sortActiveSpace,
  setSwapChannelsInSpace,
  removeChannelFromSpace,
  addChannelToActiveSpace,
  clearSpaceWithNewChannel,
  appendChannelToActiveSpace,
  removeChannelFromActiveSpace,
  removeChannelsFromActiveSpace,
} = spacesSlice.actions;

export default spacesSlice.reducer;
