import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import connect from "../socket/socket";

const initialState = {tagsById: {}, tags: []};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags(state, action) {
      return action.payload.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {});
    },
    updateTag(state, action) {
      state[action.payload.tag.id] = action.payload.tag;
    },
    addTag(state, action) {
      console.log('Adding tag', action.payload.tag);
      state[action.payload.tag.id] = action.payload.tag;
    }
  },
});

export const {
  setTags,
  updateTag,
  addTag,
} = tagsSlice.actions;

export default tagsSlice.reducer;
