import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, EyeIcon, EyeSlashIcon, TagIcon} from "@heroicons/react/24/outline";
import {Fragment, useContext} from "react";
import {useDispatch} from "react-redux";
import ChannelContext from "../Contexts/ChannelContext";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";

export default function ChannelHeaderChannelTitleDropdownMenu({showTags, toggleShowTags}) {
  const {channelId} = useContext(ChannelContext);
  const dispatch = useDispatch();

  return (
    <Menu as="div" className="relative flex">
      <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-white border-0">
        <ChevronDownIcon className="text-gray-400 h-5 w-5 hover:text-gray-100 transition cursor-pointer" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="divide-gray-600 divide-y absolute left-0 z-10 mt-7 w-48 origin-top-left rounded-md bg-gray-700 text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item as="div" className="p-2 flex items-center space-x-2" onClick={() => {
            dispatch(showModal({
              modalId: MODAL_IDS.MODAL_TAGS_MANAGER,
              data: {channelId: channelId},
            }))
          }}>
            <>
              <TagIcon className="h-5 w-5" />
              <div className="font-semibold text-sm">Manage tags</div>
            </>
          </Menu.Item>
          <Menu.Item as="div" className="p-2 flex items-center space-x-2" onClick={toggleShowTags}>
            {showTags ? (
              <>
                <EyeSlashIcon className="h-5 w-5" />
                <div className="font-semibold text-sm">Hide tags</div>
              </>
            ) : (
              <>
                <EyeIcon className="h-5 w-5" />
                <div className="font-semibold text-sm">Show tags</div>
              </>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
