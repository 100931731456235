import {TagIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {classNames} from "../utils/classes";
import {getRgbString} from "../utils/colors";

export default function ChannelTag({className, fill, onClickRemove, onClick, tagId, tagName, tagColor}) {
  const tagsById = useSelector(state => state.tags);

  const tag = {...tagsById[tagId]};

  if (tagName) tag.name = tagName;
  if (tagColor) tag.color = tagColor;

  const rgbString = getRgbString(tag.color);

  return (
    <div
      onClick={onClick}
      style={{borderColor: rgbString, backgroundColor: fill ? rgbString : ''}}
      className={classNames(className, 'border truncate rounded-md flex space-x-1 px-1.5 items-center text-white')}
    >
      <TagIcon className="text-white w-4 h-4 text-white"/>
      <span>{tag.name}</span>
      {onClickRemove ? <XCircleIcon className="h-5 w-5" onClick={onClickRemove}/> : null}
    </div>
  )
}
