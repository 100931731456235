import {ArrowDownIcon} from "@heroicons/react/24/outline";
import {ErrorMessage, Field, Formik} from "formik";
import {useEffect, useState} from "react";
import {useSearchParam} from "react-use";
import validator from "validator";
import {useLocation} from "wouter";
import * as Yup from 'yup';
import Spinner from "./Spinner";
import {classNames} from "./utils/classes";
import {passwordAddition} from "./utils/validation";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Cannot be longer than 30')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Cannot be longer than 30')
    .required('Required'),
  email: Yup.string()
    .test('is-email', 'Invalid email', (value) => {
      return validator.isEmail(value);
    })
    .required('Required'),
  password1: passwordAddition,
  password2: passwordAddition,
  termsofservice: Yup.string().oneOf(['true'], 'You must agree to the Terms of Service'),
});

function SignupForm({submitting, initialValues, isInvitedSignup = false, onSubmit}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SignupSchema}
    >
      {({handleSubmit}) => (
        <form id="signup_form" onSubmit={handleSubmit}>
          <div className="px-6 pb-24 lg:pt-20 sm:pb-32 lg:px-8 lg:py-48 bg-black">
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                    First name *
                  </label>
                  <div className="mt-2.5">
                    <Field type="text" name="firstName" placeholder="First name..." className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="firstName">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                    Last name *
                  </label>
                  <div className="mt-2.5">
                    <Field type="text" name="lastName" placeholder="Last name..." className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="lastName">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                    Work email *
                  </label>
                  <div className="mt-2.5">
                    <Field type="email" name="email" disabled={isInvitedSignup} placeholder="Your company email... me@company.com" autoComplete="email" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="email">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block text-sm font-semibold leading-6 text-white">
                    Company
                  </label>
                  <div className="mt-2.5">
                    <Field type="text" required={false} name="company" placeholder="Your company name... [optional]" autoComplete="company" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="company">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="password1" className="block text-sm font-semibold leading-6 text-white">
                    Password *
                  </label>
                  <div className="mt-2.5">
                    <Field type="password" placeholder="**********" name="password1" autoComplete="new-password" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="password1">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="password2" className="block text-sm font-semibold leading-6 text-white">
                    Confirm password *
                  </label>
                  <div className="mt-2.5">
                    <Field type="password" placeholder="**********" name="password2" autoComplete="new-password" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="password2">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <div className="relative flex gap-x-3 col-span-full">
                    <div className="flex items-center">
                      <Field type="checkbox" name="termsofservice" className="h-4 w-4 rounded border-white/80 bg-white/90 text-orange-600 focus:ring-orange-600 focus:ring-offset-gray-900" />
                    </div>
                    <div className="text-sm leading-6 flex justify-between w-full">
                      <p className="text-gray-100 font-semibold">
                        I agree to the
                        {' '}
                        <a target="_blank" href="https://fuserisk.com/terms" className="hover:underline text-gray-100 font-semibold">Terms of Service</a>
                      </p>
                    </div>
                  </div>
                  <ErrorMessage name="termsofservice">
                    {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                  </ErrorMessage>
                </div>
              </div>
              <div className="mt-8 flex items-center justify-between">
                <a className="hover:underline" href="/login" tabIndex={-1}>Back to Login</a>
                <button
                  disabled={submitting}
                  type="submit"
                  className="flex items-center space-x-2 disabled:opacity-50 rounded-md bg-orange-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                >
                  {submitting ? <Spinner className="h-5 w-5" /> : null }
                  <div>{submitting ? 'Signing Up!' : 'Sign Up'}</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default function Signup() {
  const [, navigate] = useLocation();
  const [invitationData, setInvitationData] = useState(null);
  const [signingUp, setSigningUp] = useState(false);
  const [responseMsg, setResponseMessage] = useState(null);
  const [userCreationSuccess, setUserCreationSuccess] = useState(null);

  const inviteToken = useSearchParam('invite');
  const [loadingInviteToken, setLoadingInviteToken] = useState(!!inviteToken);

  useEffect(() => {
    if (!inviteToken) return;

    setLoadingInviteToken(true);
    fetch(`/api/signup?invite_token=${inviteToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => resp.json())
    .then(({success, msg, invitation}) => {
      if (success) return setInvitationData(invitation);

      setLoadingInviteToken(false);
      setResponseMessage(msg);
      setUserCreationSuccess(success);
      navigate('/signup', {replace: true});
    })
    .finally(() => {
      setTimeout(() => setLoadingInviteToken(false), 3000);
    });
  }, [inviteToken]);

  function handleSignup(data, formFunctions) {
    const {setFieldError, resetForm} = formFunctions;
    if (data.password1 !== data.password2) {
      setFieldError("password2", 'Passwords must match.');
      return;
    }

    setSigningUp(true);

    fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify({...data, invitationToken: inviteToken}),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((resp) => resp.json())
    .then((resp) => {
      const {success, msg} = resp;
      setResponseMessage(msg);
      setUserCreationSuccess(success);
      if (success) {
        setTimeout(() => navigate('/login'), 10000);
        resetForm();
      }
    })
    .finally(() => {
      setSigningUp(false);
    });
  }

  return (
    <div className="relative isolate bg-black/80 h-full">
      { loadingInviteToken ? (
        <div className="z-50 bg-black/75 backdrop-blur-sm h-full w-full absolute top-0 left-0 flex items-center justify-center space-x-5 font-semibold">
          <Spinner className="h-8 w-8" />
          <p className="text-2xl">Loading invitation...</p>
        </div>
      ) : null}
      <div className="w-full fixed top-0 left-0 z-50">
        <div className={classNames(
          responseMsg === null ? 'hidden' : '',
          userCreationSuccess ? 'border-green-900 bg-green-700' : 'border-red-900 bg-red-700',
          "transition mx-auto max-w-2xl rounded-b-lg border-2 border-t-0 p-3 text-white font-semibold text-center"
        )}>
          <div>{responseMsg}</div>
        </div>
      </div>
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2 h-full">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48 h-full">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-orange-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="100%" y={-1} className="overflow-visible fill-orange-800/20">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
              </svg>
              <div
                className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#FE6000] to-[#000] opacity-20"
                  style={{
                    clipPath:
                      'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <h2 className="text-3xl font-bold tracking-tight text-white">Sign up for fuse</h2>
              <a href="#signup_form" className="font-semibold ring-2 ring-orange-600 cursor-pointer flex items-center space-x-2 rounded-md bg-transparent px-3.5 py-1.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500 visible lg:hidden">
                Sign up below
                <ArrowDownIcon className="animate-bounce h-4 w-4 ml-3" />
              </a>
            </div>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              fuse is a modern communication hub tailored for the commercial insurance industry
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">
              <h2 className="font-bold text-lg">Who uses Fuse?</h2>
              <div className="gap-x-4">
                <div className="capitalize">retail brokers</div>
                <div className="capitalize">wholesale brokers</div>
                <div className="capitalize">fac reinsurance brokers</div>
                <div className="capitalize">treaty reinsurance brokers</div>
                <div className="capitalize">insurance underwriters</div>
                <div className="capitalize">excess underwriters</div>
                <div className="capitalize">surplus lines underwriters</div>
                <div className="capitalize">fac reinsurance underwriters</div>
                <div className="capitalize">treaty reinsurance underwriters</div>
              </div>
            </dl>
          </div>
        </div>
        {
          loadingInviteToken ? null : (
            <SignupForm
              submitting={signingUp}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                company: '',
                phoneNumber: '',
                password1: '',
                password2: '',
                termsofservice: false,
                ...invitationData
              }}
              isInvitedSignup={!!invitationData}
              onSubmit={handleSignup} />
          )
        }
      </div>
    </div>
  )
}
