import {useEffect} from 'react'
import {Provider} from "react-redux";
import {useFavicon} from "react-use";
import {Route, Router} from "wouter";
import Dashboard from "./Dashboard";
import Login from "./Login";
import {useLocation} from "wouter";
import withSocket from "./hoc/withSocket";
import Reset from "./Reset";
import Signup from "./Signup";
import {store} from "./store";
import logoLines from './assets/logo-lines.png';
import './App.css'

function App() {
  useFavicon(logoLines);
  const [path, navigate] = useLocation();

  useEffect(() => {
    if (path === '/signup') return;

    fetch('/api/me', {method: 'GET'})
      .then((resp) => resp.json())
      .then(({user}) => {
        user && navigate('/');
      });

    function visibilityChanged() {
      window.localStorage.setItem('isActive', !document.hidden);
    }

    window.addEventListener('visibilitychange', visibilityChanged);

    return () => {
      window.removeEventListener('visibilitychange', visibilityChanged);
    }
  }, []);

  return (
    <Router>
      <Route path='/login' component={Login} />
      <Route path='/signup' component={Signup} />
      <Route path='/reset' component={Reset} />
      <Route path='/' component={() => {
        return (
          <Provider store={store}>
            <DashboardWithSocket />
          </Provider>
        )
      }} />
    </Router>
  );
}

const DashboardWithSocket = withSocket(Dashboard);

export default App;
