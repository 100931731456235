import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useRef, useState} from "react";
import {useSearchParam} from "react-use";
import {useLocation} from "wouter";
import * as Yup from "yup";
import FuseLogoText from "./FuseLogoText";
import Spinner from "./Spinner";
import logoLines from './assets/logo-lines.png';
import {classNames} from "./utils/classes";
import validator from 'validator';
import {passwordAddition} from "./utils/validation";

const ResetSchema = Yup.object().shape({
  password1: passwordAddition,
  password2: passwordAddition.oneOf([Yup.ref('password1'), null], "Passwords must match"),
  reset_token: Yup.string(),
});

export default function Reset() {
  const emailInputRef = useRef(null);
  const formRef = useRef(null);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashType, setFlashType] = useState(null);
  const resetToken = useSearchParam('token');

  const [, navigate] = useLocation();

  function startPasswordReset() {
    setResettingPassword(true);

    fetch(`/api/password-reset`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => resp.json())
    .then((resp) => {
      setFlashType(resp.success ? 'success' : 'error');
      setFlashMessage(resp.msg);
      if (resp.success) emailInputRef.current.value = '';
    })
    .finally(() => {
      setResettingPassword(false);
    });
  }

  function performPasswordReset(data) {
    setResettingPassword(true);

    fetch(`/api/password-reset`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(resp => resp.json())
    .then(({success, msg, redirect}) => {
      setFlashType(success ? 'success' : 'error');
      setFlashMessage(msg);

      if (success && redirect) setTimeout(() => navigate(redirect), 3000);
    }).finally(() => {
      setResettingPassword(false);
    });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="-mt-20">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-5">
          <div className="flex items-center justify-center space-x-2">
            <img
              className="h-12 w-auto"
              src={logoLines}
              alt="Your Company"
            />
            <FuseLogoText />
          </div>
          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">

          </h2>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className={classNames(
            flashMessage ? '' : 'hidden',
            flashType === 'error' ? 'border-red-500 bg-red-500/50' : '',
            flashType === 'success' ? 'border-green-500 bg-green-500/50' : '',
            'mx-auto max-w-lg font-semibold border-2 p-3 text-white rounded-lg text-center my-5'
          )}>
            {flashMessage}
          </div>
          {
            !resetToken ? (
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    ref={emailInputRef}
                    id="email"
                    name="user_email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Your fuse account email address..."
                    onChange={({target: {value}}) => {
                      setEmail(validator.isEmail(value) ? value : null);
                    }}
                    className="block w-full rounded-md border-0 bg-white/5 px-2 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-5 divide-y divide-gray-500 space-y-5">
                  <button
                    onClick={() => startPasswordReset()}
                    disabled={!email}
                    className="disabled:opacity-50 flex items-center w-full justify-center rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    {resettingPassword ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" /> : null}
                    <span>Reset password</span>
                  </button>
                </div>
              </div>
            ) : (
              <Formik
                onSubmit={performPasswordReset}
                validationSchema={ResetSchema}
                initialValues={{token: resetToken, password1: null, password2: null}}
                className="space-y-3"
              >
                {({isValid, dirty}) => {
                  return (
                    <Form ref={formRef} className="space-y-4">
                      <Field type="hidden" name="token" value={resetToken} />
                      <Field
                        type="password"
                        name="password1"
                        placeholder="Secure password"
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                      />
                      <ErrorMessage name="password1">
                        {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                      </ErrorMessage>
                      <Field
                        type="password"
                        name="password2"
                        placeholder="Confirm secure password"
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                      />
                      <ErrorMessage name="password2">
                        {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                      </ErrorMessage>
                      <button
                        type="submit"
                        disabled={!isValid || !dirty || resettingPassword}
                        className="disabled:opacity-50 flex items-center w-full justify-center rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                      >
                        {resettingPassword ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" /> : null}
                        <span>Reset password</span>
                      </button>
                    </Form>
                  )
                }}
              </Formik>
            )
          }
          <div className="border-t border-gray-500 pt-5 mt-5">
            <a
              href={'/login'}
              className="flex items-center w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              <span>Back to Login</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
