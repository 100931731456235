import {useContext, useEffect, useMemo, useState} from 'react';
import {
  DragOverlay,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import DashboardContext from "../Contexts/DashboardContext";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import {sortActiveSpace} from "../reducers/spacesSlice";
import SortableItem from './SortableItem';
import Item from './Item';
import {PlusIcon, SquaresPlusIcon, TvIcon} from "@heroicons/react/24/outline";
import Header, {SORT_OPERATIONS} from '../Dashboard/Header';
import {useDispatch, useSelector} from "react-redux";

export default function SortableChannelGrid({activeId}) {
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);

  const {activeSpace} = useSelector((state) => state.spaces);
  const channelsById = useSelector((state) => state.channels);

  const {setShowNewRoomModal} = useContext(DashboardContext);

  const dispatch = useDispatch();

  const activeChannels = useMemo(() => {
    return activeSpace.channels.map((id) => channelsById[id]);
  }, [activeSpace.channels, channelsById]);

  useEffect(() => {
    if (!sort) return;

    const {desc, key} = SORT_OPERATIONS[sort];

    const [greaterThan, lessThan] = desc ? [1, -1] : [-1, 1];

    let sorted = [];

    switch (sort) {
      case 'insertedAtDesc':
      case 'insertedAtAsc':
      case 'latestMessageAsc':
      case 'latestMessageDesc':
        sorted = activeChannels.sort((a, b) => {
          return new Date(a.metadata[key]).getTime() > new Date(b.metadata[key]).getTime() ? greaterThan : lessThan;
        });
        break;
      case 'nameDesc':
      case 'nameAsc':
        sorted = activeChannels.sort((a, b) => {
          if (a.metadata.name > b.metadata.name) return greaterThan;
          if (a.metadata.name < b.metadata.name) return lessThan;
          return 0;
        });
        break;
    }

    dispatch(sortActiveSpace(sorted.map(({id}) => parseInt(id))));
    setSort(null);
  }, [sort]);

  if (!channelsById) return null;

  const spaceChannelIds = activeSpace.channels.map((id) => `grid:${id}`);

  return (
    <>
      <Header setFilter={setFilter} setSort={setSort} />
      { !spaceChannelIds.length && (
        <div className="flex flex-col sm:space-y-0 space-y-7 md:flex-row items-center justify-around text-center mt-20 mx-20 border-2 border-gray-100/50 rounded-lg m-5 p-10 border-dashed">
          <div>
            <TvIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-white">Create or add a channel</h3>
            <p className="mt-1 text-sm text-gray-300 text-sm font-semibold">Get started by creating a new channel<br/> or add an existing channel in the menu.</p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => setShowNewRoomModal(true)}
                className="inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                New Channel
              </button>
            </div>
          </div>
          <div>
            <SquaresPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-white">Create a new Space</h3>
            <p className="mt-1 text-sm text-gray-300 text-sm font-semibold">Get started by creating a new space to<br/> help organize channels.</p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => dispatch(showModal({modalId: MODAL_IDS.MODAL_NEW_BLANK_SPACE}))}
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                New Space
              </button>
            </div>
          </div>
        </div>
      ) }
      <SortableContext items={spaceChannelIds} strategy={rectSortingStrategy}>
        <div
          className="sm:grid sm:gap-6 max-w-full sm:m-6 sm:mb-4 m-4 sm:space-y-0 space-y-2 grid-cols-2 lg:grid-cols-3"
        >
          {
            activeSpace.channels.map((channelId) => {
              const channel = channelsById[channelId];

              if (!channel) return null;
              if (filter && !channel.slug.toLowerCase().includes(filter.toLowerCase())) return null;

              const itemId = `grid:${channel.id}`;
              return <SortableItem key={itemId} id={itemId} channel={channel} notDraggable={!!filter} />
            })
          }
        </div>
      </SortableContext>
      <DragOverlay adjustScale={false} style={{ transformOrigin: '0 0 ' }}>
        {activeId ? <Item id={activeId} isOverlay isDragging /> : null}
      </DragOverlay>
    </>
  );
};

