import {Menu, Transition} from "@headlessui/react";
import {
  Bars4Icon,
  ChevronDownIcon,
  Cog6ToothIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {Fragment, useContext, useState} from "react";
import {useSelector} from "react-redux";
import ChannelContext from "../Contexts/ChannelContext";
import {Tooltip} from 'react-tippy';
import {useActiveChannelMembersSelector} from "../selectors/channelSelectors";
import {classNames} from "../utils/classes";
import ChannelHeaderChannelTitleDropdownMenu from "./ChannelHeaderChannelTitleDropdownMenu";
import ChannelHeaderCloseMenuDropdown from "./ChannelHeaderCloseMenuDropdown";
import ChannelTag from "./ChannelTag";

export default function ChannelMessagesHeader() {
  const {
    handleProps,
    setShowSettings,
    notDraggable,
    shrinkable,
    isDragging,
    setShowMessageList,
    showMessageList,
    channelId,
    channelLocked,
    channelIsArchived,
    setShowUserInfoSlideover,
  } = useContext(ChannelContext);

  const [showTags, setShowTags] = useState(false);

  const channel = useSelector(state => state.channels[channelId]);
  const channelMembers = useActiveChannelMembersSelector(channelId);
  const memberStatuses = useSelector(state => state.memberStatuses);

  return (
    <div className="border-b border-gray-500 bg-gray-800">
      <div className={`${classNames(showTags ? '' : 'pb-2.5', 'p-2 pb-0 flex-1 w-full text-gray-100 font-bold text-lg flex items-center justify-between bg-gray-800')}`}>
        <div className="text-gray-500 cursor-pointer flex space-x-2 items-center">
          {
            notDraggable ? (
              <>
                {shrinkable ? <ChevronDownIcon className={classNames(!showMessageList ? '-rotate-90' : '', 'transition h-7')} onClick={() => setShowMessageList(!showMessageList)} /> : null }
              </>
            ) : (
              <Bars4Icon {...handleProps.attributes} {...handleProps.listeners} tabIndex={-1} className={classNames(isDragging ? 'cursor-grabbing' : 'cursor-grab', "h-7 w-7")} />
            )
          }
          <ChannelHeaderCloseMenuDropdown />
          <ChannelHeaderChannelTitleDropdownMenu showTags={showTags} toggleShowTags={() => setShowTags(!showTags)} />
        </div>
        <div className="flex items-center space-x-2 truncate px-2 w-full">
          <div className="truncate flex flex-col justify-center h-10">
            <Tooltip html={(
              <div className="text-left space-y-3">
                <div className="">{channel.slug}</div>
                {channel.description ? <div>{channel.description}</div> : null}
              </div>
            )} position="bottom">
              <span className="w-1/2 text-md font-semibold leading-1">{channel.slug}</span>
              { channel.description ? <div className={classNames("text-xs font-normal leading-1 -mt-1.5 text-gray-400 truncate")}>{channel.description}</div> : null }
            </Tooltip>
          </div>
        </div>
        <div className="space-x-2 flex items-center">
          {
            !channelLocked && !channelIsArchived ? (
              <div className="flex items-center">
                <Cog6ToothIcon className="h-7 text-gray-600 hover:text-white transition cursor-pointer" onClick={() => setShowSettings(true)} />
              </div>
            ) : null
          }
          <div className="relative">
            <Menu as="div" className="relative grow flex justify-end">
              <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-white border-0">
                <UsersIcon className="text-gray-600 h-7 w-7 hover:text-gray-400 transition cursor-pointer" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="divide-gray-600 max-h-[20em] overflow-y-auto overflow-hidden divide-y absolute right-0 z-10 mt-7 w-48 origin-top-right rounded-md bg-gray-700 text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {
                    channelMembers.map((memberId) => {
                      const member = memberStatuses[memberId];
                      if (!member) return null;

                      return (
                        <Menu.Item
                          className="p-2"
                          key={`member_channel_members_list_dropdown_${member.id}`}
                          onClick={() => setShowUserInfoSlideover({...member, id: member.user_id, isChannelAdmin: channel.created_by === member.user_id})}
                        >
                          {({ active }) => {
                            if (!member) return null;
                            const {avatar_url, username, first_name, last_name} = member;
                            return (
                              <div className={classNames(active ? 'bg-gray-500' : '', 'flex items-center space-x-4')}>
                                <img key={`channel_header_profile_stack_${username}`} className="inline-block w-7 rounded-md" src={avatar_url} alt={username}/>
                                <div>
                                  <div className="font-semibold text-sm">{first_name + ' ' + last_name}</div>
                                  <div className="font-semibold text-gray-300 text-xs">@{username}</div>
                                </div>
                              </div>
                            );
                          }}
                        </Menu.Item>
                      )
                    })
                  }
                </Menu.Items>
              </Transition>
            </Menu>
            <span className="absolute -bottom-1 -right-1 bg-gray-100 rounded-full h-4 w-4 text-center scale-75 text-xs text-black p-0">{channelMembers.length}</span>
          </div>
        </div>
      </div>
      {
        showTags ? (
          <div className="flex space-x-1 mt-2 overflow-x-auto px-1 mb-1 relative">
            {channel.tags.length ? null : <span className="italic text-sm">No tags... Try adding some?</span>}
            {
              channel.tags.map(tag => (
                <span key={`channel_header_tags_${tag.id}`}>
                  <ChannelTag className="h-5 text-xs" tagId={tag.id} fill />
                </span>
              ))
            }
          </div>
        ) : null
      }
    </div>
  )
}
