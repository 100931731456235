import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ChannelMessagesHeader from "./Channel/ChannelMessagesHeader";
import CompanyInfoSlideover from "./Channel/CompanyInfoSlideover";
import UserInfoSlideover from "./Channel/UserInfoSlideover";
import useSocket, {useSocketEventWithFilter} from './hooks/useSocket';
import {ArrowDownIcon, SquaresPlusIcon} from '@heroicons/react/24/outline';
import {
  appendManyMessages,
  prependManyMessages, setChannelLockToBottom, setManyMessages,
  setMaxSeenMessageThunk,
  setReadStatuses
} from "./reducers/channelsSlice";
import {getChannelMembersThunk} from "./reducers/membersSlice";
import debounce from 'lodash.debounce';
import Spinner from "./Spinner";
import {classNames} from './utils/classes';
import Message from './Channel/Message';
import Editor from './Channel/Editor';
import TypingIndicator from "./Channel/TypingIndicator";
import ChannelContext from './Contexts/ChannelContext';
import MessageDateSeparator from "./Channel/MessageDateSeparator";
import InfiniteScroll from 'react-infinite-scroll-component';
import SettingsOverlay from "./Channel/SettingsOverlay";
import DateFormatter from "./utils/DateFormatter";

function InfinityLoader({bottom = false, loading = false}) {
  if (loading) {
    return (
      <div className="flex items-center justify-center py-4 font-bold">
        <Spinner className="h-5 w-5 mr-2" />
        <span>Loading more messages...</span>
      </div>
    );
  }

  return (
    <div className="text-md font-bold py-4 flex items-center justify-center">
      <div className="scale-75"><ArrowDownIcon className={classNames(!bottom ? 'rotate-180' : '', 'h-5 w-5 mr-2')} /></div>
      <div className="-ml-1.5">Keep scrolling to load</div>
    </div>
  );
}

export default function Channel({
  channelId,
  slug: name,
  notDraggable,
  shrinkable = false,
  startAtMessageId,
  onClose,
  handleProps = {},
  isOverlay = false,
  isDragging = false,
  ...props
}) {
  const dispatch = useDispatch();
  const messageListBottomRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const infiniteScrollContainerRef = useRef(null);

  const messages = useSelector(state => state.channels[channelId].messages);
  const readStatuses = useSelector(state => state.channels[channelId].readStatuses);
  const maxSeenMessage = useSelector(state => state.channels[channelId].maxSeenMessage);
  const membersMap = useSelector(state => state.channels[channelId].members);
  const channel = useSelector(state => state.channels[channelId]);
  const {user} = useSelector(state => state.user);

  const [lockToBottom, setLockToBottom] = useState(false);
  const [focused, setFocused] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [initScrollMessageId, setInitScrollMessageId] = useState(startAtMessageId);
  const [allMessagesHaveLoaded, setAllMessagesHaveLoaded] = useState(false);
  const [initialScrollComplete, setInitialScrollComplete] = useState(false);
  const [showMessageList, setShowMessageList] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showCloseOptions, setShowCloseOptions] = useState(false);
  const [noNewerMessages, setNoNewerMessages] = useState(false);
  const [showUserInfoSlideover, setShowUserInfoSlideover] = useState(null);
  const [showCompanyInfoSlideover, setShowCompanyInfoSlideover] = useState(null);
  const [attachmentUuids, setAttachmentUuids] = useState([]);
  const [canScroll, setCanScroll] = useState(null);
  const [socket, connected] = useSocket();

  useEffect(() => {
    if (!socket || !connected) return;

    dispatch(getChannelMembersThunk({channelId}));
  }, [socket, connected]);

  useEffect(() => {
    dispatch(setChannelLockToBottom({channelId, lockToBottom}))
  }, [lockToBottom]);

  useEffect(() => {
    const {scrollHeight, clientHeight} = infiniteScrollContainerRef.current.el;
    setCanScroll(scrollHeight !== clientHeight);
  }, [messages]);

  // Incoming read update data
  useSocketEventWithFilter('read_update', ['channelId', channelId], ({readStatuses}) => {
    dispatch(setReadStatuses({channelId, userId: user.id, readStatuses}));
  });

  useEffect(() => {
    Object.keys(readStatuses).forEach((messageId) => {
      const foundReadStatus = readStatuses[messageId].find(({id}) => id === user.id);
      if (foundReadStatus && !initScrollMessageId) {
        setInitScrollMessageId(messageId);
      }
    });
  }, [readStatuses, user]);

  // Get more messages in inf scroll up
  function getMoreMessages() {
    setShowLoading(true);

    socket.emit('get_messages', {
      range: [messages.slice(-1)[0].id, -10, -1],
      channel_id: channelId,
    }, ({messages: pastMessages}) => {
      pastMessages = pastMessages.slice(2);

      if (!pastMessages.length) {
        setAllMessagesHaveLoaded(true);
        return;
      }

      // Slow the user down, in the future we should just debounce...
      setTimeout(() => {
        setShowLoading(false);
        dispatch(prependManyMessages({channelId, messages: pastMessages}));
      }, 1000);
    });
  }

  useEffect(() => {
    if (!infiniteScrollContainerRef.current?.el) return;

    function onScroll (event) {
      if (event.target.scrollTop === 0) {
        setShowLoading(true);
        setTimeout(() => {
          socket.emit('get_messages', {channel_id: channelId, range: [messages[0].id, 0, 5]}, ({messages, readStatuses}) => {
            infiniteScrollContainerRef.current.el.scrollTo({top: -55, behavior: 'smooth'});

            setShowLoading(false);

            if (!messages.length) setNoNewerMessages(true);

            dispatch(messages.length ? setManyMessages({channelId, messages}) : appendManyMessages({channelId, messages}));
            dispatch(setReadStatuses({channelId, readStatuses, userId: user.id}));
          });
        }, 1000);
      }
    }

    infiniteScrollContainerRef.current.el.addEventListener('scroll', onScroll);

    return () => {
      infiniteScrollContainerRef.current?.el?.removeEventListener('scroll', onScroll);
    }
  }, [infiniteScrollContainerRef, initialScrollComplete, messages, showLoading, noNewerMessages]);

  // Init get messages
  useEffect(() => {
    getCurrentMessages(startAtMessageId);
  }, [socket, connected, user]);

  function getCurrentMessages(startAtMessageId, withRange) {
    if (!socket || isDragging || !user) return;

    const range = startAtMessageId ? [startAtMessageId, -5, 5] : null;

    socket.emit('get_messages', {channel_id: channelId, range}, ({messages, readStatuses}) => {
      dispatch(messages.length ? setManyMessages({channelId, messages}) : appendManyMessages({channelId, messages}));
      dispatch(setReadStatuses({channelId, readStatuses, userId: user.id}));
    });
  }

  // Detect the intersection of messages in the list
  useEffect(() => {
    if (!messages.length || !messageListBottomRef.current) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        setLockToBottom(entry.intersectionRatio === 1);
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.5, 1.0],
    });

    observer.observe(messageListBottomRef.current);

    return () => observer.disconnect();
  }, [messages.length, messageListBottomRef, messagesContainerRef]);

  // Scroll to the bottom when new messages come in and we're locked to the bottom of the screen.
  useEffect(() => {
    // When we get a new message and we're locked to the bottom of the screen, scroll to see the new message
    if (lockToBottom) scrollToMessagesBottom(messageListBottomRef.current);
  }, [messages.length, lockToBottom]);

  // When messages changes, scroll to the bottom of the channel message list given some conditions
  const refCallback = useCallback((node) => {
    if (messages.length && !initialScrollComplete && node && messagesContainerRef.current) {
      scrollToMessagesBottom(node);
    }
  }, [messages]);

  // Handle scroll to bottom
  function scrollToMessagesBottom(anchor) {
    if (!anchor) return;

    const scrollComplete = debounce(() => {
      infiniteScrollContainerRef.current.el.removeEventListener('scroll', onScroll);

      if (!initialScrollComplete) {
        setInitialScrollComplete(true);
      }
    }, 100);

    function onScroll(_event) {
      scrollComplete();
    }

    infiniteScrollContainerRef.current.el.addEventListener('scroll', onScroll);
    infiniteScrollContainerRef.current.el.scrollTo({
      top: anchor.offsetTop + anchor.clientHeight - infiniteScrollContainerRef.current.el.clientHeight,
      block: 'start',
      behavior: 'smooth'
    });
  }

  function getMember(userId) {
    const member = membersMap[userId];
    return member || {};
  }

  const onIntersecting = debounce((message) => {
    if (initialScrollComplete) {
      const maxMessageId = Math.max(maxSeenMessage, message.id);
      dispatch(setMaxSeenMessageThunk({id: channelId, maxSeenMessage: maxMessageId}));
    }
  }, 2000);

  if (!user) return null;

  const userMember = getMember(user.id);
  const channelIsArchived = channel.archived;
  const channelLocked = !!userMember.removed_from_channel_at_message_id;
  const showEditor = channelLocked || channelIsArchived;

  return (
    <ChannelContext.Provider value={{
      lockToBottom,
      notDraggable,
      shrinkable,
      showMessageList,
      setShowMessageList,
      setShowCloseOptions,
      showCloseOptions,
      handleProps,
      channelId,
      name,
      onClose,
      attachmentUuids,
      setAttachmentUuids,
      setShowSettings,
      showSettings,
      setShowUserInfoSlideover,
      setShowCompanyInfoSlideover,
      channelLocked,
      channelIsArchived,
    }}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={classNames(props.starred ? 'ring-yellow-600' : null, `w-full h-full rounded-sm transition-all bg-gray-800 ring-2 text-gray-500 shadow-xl`, focused ? 'ring-orange-500' : 'ring-gray-500')}
      >
        <div className="relative h-full overflow-x-hidden">
          <SettingsOverlay />
          <UserInfoSlideover show={!!showUserInfoSlideover} userData={showUserInfoSlideover} />
          <CompanyInfoSlideover show={!!showCompanyInfoSlideover} companyData={showCompanyInfoSlideover} />
          <div className="flex flex-col justify-between bg-gray-700 max-h-[35rem] relative">
            <ChannelMessagesHeader isDragging={isDragging} />
            <div className="absolute left-0 w-full top-12 bg-gray-800 z-40 flex items-center justify-center">
              <TypingIndicator />
            </div>
            {
              showMessageList && (
                <>
                  {
                    isDragging && isOverlay ? (
                      <div className="mx-auto text-xl mt-20 text-gray-100 relative flex flex-column justify-center items-center h-full">
                        <div>
                          <div className="w-full text-lg font-bold">Drag me around! ✈️</div>
                          <SquaresPlusIcon className="mt-10 mx-auto h-20 w-20" />
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    !messages.length && !isDragging ? (
                      <div className="mx-auto text-lg text-gray-100 mt-10">Start the conversation! 📢</div>
                    ) : null
                  }
                  <div
                    id={`messages_${channelId}_list`}
                    ref={messagesContainerRef}
                    style={{overscrollBehavior: 'contain'}}
                    className={'h-[24rem] flex flex-col flex-1 overflow-y-auto flex-initial relative'}
                  >
                    <InfiniteScroll
                      ref={infiniteScrollContainerRef}
                      scrollableTarget={`messages_${channelId}_list`}
                      dataLength={messages.length}
                      className={'flex text-sm text-white w-full flex-col flex-col-reverse'}
                      inverse={true}
                      onScroll={(event) => {
                        setLockToBottom(event.target.scrollTop > -65)
                      }}
                      next={() => getMoreMessages()}
                      hasMore={!allMessagesHaveLoaded}
                      endMessage={(
                        <div className="text-md font-bold py-4 text-center px-4">
                          <div>✅ And that's all she wrote</div>
                        </div>
                      )}
                      loader={<InfinityLoader loading={showLoading} />}
                      height={`${messagesContainerRef.current?.clientHeight}px`}
                    >
                      <>
                        { canScroll ? (
                          <InfinityLoader loading={showLoading} bottom />
                        ) : null }
                        <div ref={messageListBottomRef} id={`message_list_anchor_${channelId}`}></div>
                      </>
                      {
                        messages.map((message, idx) => {
                          let readers = [];

                          // TODO: can this be memoized? - Cecil, just passing through
                          if (readStatuses[message.id]) {
                            if (Object.values(membersMap).length) {
                              readers = readStatuses[message.id].map(({user_id, avatar_url}) => {
                                return {avatarUrl: avatar_url, username: getMember(user_id).username};
                              });
                            }
                          }

                          return (
                            <div key={`message_container_${message.id}`} className={classNames(`container_${message.id}`, isDragging && isOverlay ? 'hidden' : 'visible')}>
                              <MessageDateSeparator message={message} prevMessage={messages[idx + 1]} />
                              <div ref={initScrollMessageId === message.id ? refCallback : null}>
                                <Message
                                  message={message}
                                  key={`message_${message.id}_${initialScrollComplete ? 'ready' : 'notready'}`}
                                  observe={initialScrollComplete && message.id > maxSeenMessage}
                                  isIntersecting={() => onIntersecting(message)}
                                >
                                  <>
                                    {
                                      readers && (
                                        <div className="absolute bottom-1.5 right-2 flex space-x-1 justify-end">
                                          {
                                            readers.map((reader) => {
                                              const {avatarUrl, username} = reader;
                                              return (
                                                <img key={`user_${username}_read_receipts`} className="rounded-full h-4 w-4" alt={`User Profile Picture ${username}`} src={avatarUrl} />
                                              )
                                            })
                                          }
                                        </div>
                                      )
                                    }
                                  </>
                                </Message>
                              </div>
                            </div>
                          )
                        })
                      }
                    </InfiniteScroll>
                  </div>
                  <div className="max-h-48 min-h-20 bg-gray-800 relative p-2 flex-auto transition-all absolute bottom-0 left-0 w-full">
                    {
                      channelIsArchived ? (
                        <div className="my-5">
                          <div className="min-h-32 text-white italic w-full text-center">Archived Channel</div>
                          <div className="min-h-32 text-gray-500 italic w-full text-center text-sm">
                            This channel was archived @ <DateFormatter dateString={channel.archived_at} />
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      channelLocked ? (
                        <div className="my-5">
                          <div className="min-h-32 text-white italic w-full text-center">You have been removed from this channel.</div>
                          <div className="min-h-32 text-gray-500 italic w-full text-center text-sm">
                            Channel available up to <DateFormatter dateString={userMember.removed_from_channel_at} />
                          </div>
                        </div>
                      ) : null
                    }
                    { showEditor ? null : <Editor />}
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </ChannelContext.Provider>
  );
}
