import { CSS } from "@dnd-kit/utilities";
import {useDndMonitor, useDraggable, useDroppable} from "@dnd-kit/core";
import Tooltip from "@tippyjs/react";
import DashboardContext from "../Contexts/DashboardContext";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import {
  addChannelToActiveSpace,
  launchSpace,
  updateSpaceThunk
} from "../reducers/spacesSlice";
import {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  ArchiveBoxXMarkIcon, BuildingLibraryIcon,
  ChevronDownIcon,
  RocketLaunchIcon, StarIcon,
  TvIcon
} from "@heroicons/react/24/outline";
import {StarIcon as StarSolidIcon} from "@heroicons/react/24/solid";
import useSocket from "../hooks/useSocket";
import {classNames} from '../utils/classes';
import ChannelItemMenuDropdown from "./ChannelItemMenuDropdown";
import ChannelReadCount from './ChannelReadCount';
import SpaceItemMenuDropdown from "./SpaceItemMenuDropdown";
import {isTreeParent} from './util';

export default function SortableTreeItem(props) {
  const [socket] = useSocket();
  const dispatch = useDispatch();
  const {data: {channel}, spaceId, title, id, isChannel, isSpace, enableDnd, minimizeSpace, minimized, badges} = props;
  const [isHoveringOver, setIsHoveringOver] = useState(false);
  const [spaceNameEditable, setSpaceNameEditable] = useState(null);
  const activeSpace = useSelector((state) => state.spaces.activeSpace);
  const {showSidebar, toggleShowSidebar} = useContext(DashboardContext);
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: props.id,
    data: { type: 'TREE', ...props.data }
  });
  const { attributes, listeners, setNodeRef, transform } = useDraggable({attributes: {}, id: props.id, data: props.data });

  useDndMonitor({
    onDragEnd() {
      setIsHoveringOver(false);
    },
    onDragOver(event) {
      if (event.over && isTreeParent(event.over) && event.over.id === props.id) {
        setIsHoveringOver(true);
      } else {
        setIsHoveringOver(false);
      }
    }
  });

  function openChannel() {
    if (!isChannel) return;
    if (!activeSpace.id && !activeSpace.channels.length) {
      dispatch(addChannelToActiveSpace(channel.id));
      if (showSidebar) toggleShowSidebar();
      return;
    }

    dispatch(showModal({modalId: MODAL_IDS.MODAL_CONFIRM_APPEND, data: {channelId: channel.id, spaceId}}));
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: 99999,
    borderBottom: isHoveringOver ? "2px white solid" : null,
  };

  const isArchived = isChannel && channel.archived;
  const channelIsOpen = isChannel && channel ? activeSpace.channels.includes(channel.id) : false;

  const isManaged = isSpace && props.data.parent.managed;
  const isEditable = isSpace ? isManaged ? props.data.parent.editable : true : false;
  const canHaveOptions = isSpace && isManaged ? props.data.parent.options : true;
  const spaceIsLaunched = isSpace ? activeSpace.id === props.data.parent.id : false;
  const spaceIsLaunchable = isSpace && isManaged ? props.data.parent.launchable : true;

  if (isChannel && !channel) return null;

  const dragProps = enableDnd ? {...attributes, ...listeners} : {};

  return (
    <>
      <li ref={enableDnd ? setDroppableRef : null}>
        <span
          ref={enableDnd ? setNodeRef : null}
          className={classNames(spaceIsLaunched ? 'bg-gradient-to-l from-green-500/20 border border-green-500/20' : '', 'text-gray-400 hover:text-white hover:bg-gray-600 group rounded-md p-2 text-sm leading-6 flex font-semibold items-center')}
          style={style}
          onDoubleClick={openChannel}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-x-2 items-center">
              { isSpace ? (
                <ChevronDownIcon onClick={() => minimizeSpace()} className={classNames(minimized ? '-rotate-90' : '', 'transition h-5 w-5')} aria-hidden="true" />
              ) : null }

              {
                isChannel && !isArchived ? (
                  <TvIcon className={`${enableDnd ? 'cursor-grab' : ''} shrink-0 h-4 w-4 ${channelIsOpen ? 'text-orange-500' : null}`} aria-hidden="true" {...dragProps} />
                ) : null
              }

              {
                isChannel && isArchived ? (
                  <Tooltip content="This channel has been archived">
                    <ArchiveBoxXMarkIcon className={`${enableDnd ? 'cursor-grab' : ''} shrink-0 h-4 w-4 ${channelIsOpen ? 'text-orange-500' : null}`} aria-hidden="true" {...dragProps} />
                  </Tooltip>
                ) : null
              }

              <div
                contentEditable={spaceNameEditable === id}
                suppressContentEditableWarning={true}
                onBlur={({target}) => {
                  dispatch(updateSpaceThunk({id: props.data.parent.id, newName: target.innerText})).then(() => {
                    setSpaceNameEditable(null);
                  });
                }}
                onClick={(event) => {
                  if (isChannel || !isEditable) return;
                  event.stopPropagation();
                  setSpaceNameEditable(id);
                }}>
                {
                  isChannel ? (
                    <Tooltip content={<div className={!channel.description ? 'italic' : ''}>{channel.description || 'No description'}</div>}>
                      <span>{channel.name || channel.slug}</span>
                    </Tooltip>
                  ) : title
                }
              </div>
            </div>
            {
              isChannel && (
                <div className="flex items-center space-x-1">
                  <div>
                    {badges}
                  </div>
                  <ChannelReadCount channel={channel} />
                  {
                    channel.starred ? (
                      <StarSolidIcon onClick={() => {
                        socket.emit('channels_users:update', {channelId: channel.id, starred: false});
                      }} className={classNames('text-yellow-500 h-4 w-4')} />
                    ) : (
                      <StarIcon onClick={() => {
                        socket.emit('channels_users:update', {channelId: channel.id, starred: true});
                      }} className={'h-4 w-4'} />
                    )
                  }
                  <ChannelItemMenuDropdown channel={channel} spaceId={spaceId} />
                </div>
              )
            }
            {
              isSpace && (
                <div className="flex items-center space-x-1 relative">
                  <div>
                    {badges}
                  </div>
                  {
                    spaceIsLaunchable ? (
                      <RocketLaunchIcon className={classNames(spaceIsLaunched ? 'text-green-500' : '', 'h-4 w-4 hover:text-green-500 transition')} onClick={() => {
                        dispatch(launchSpace(props.data.parent.id));
                      }} />
                    ) : null
                  }
                  {canHaveOptions ? (
                    <SpaceItemMenuDropdown space={props.data.parent} />
                  ) : null}
                  {isManaged ? (
                    <Tooltip content={
                      <div className="text-sm text-left">
                        <div>This Space is managed by Fuse</div>
                        <div className="text-xs italic text-gray-200">Options are limited</div>
                      </div>
                    }>
                      <BuildingLibraryIcon className="h-4 w-4 text-gray-400"/>
                    </Tooltip>
                  ) : null}
                </div>
              )
            }
          </div>
        </span>
      </li>
    </>
  );
}
