import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import '@fontsource/comfortaa';

const environment = process.env.NODE_ENV;

Sentry.init({
  dsn: "https://d6b5d7689a19b4096462ee306fcf8406@o4505823380897792.ingest.sentry.io/4505828479991808",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/demo\.com/],
    }),
    new Sentry.Replay(),
  ],
  debug: environment !== 'production',
  enabled: environment !== 'development',
  environment: environment,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
)
