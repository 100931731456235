import {RocketLaunchIcon, SquaresPlusIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {Squares2X2Icon} from "@heroicons/react/24/outline/index";
import React, {useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "react-tippy";
import {useKeyPressEvent} from "react-use";
import {hideModal, MODAL_IDS} from "../../reducers/modalsSlice";
import {
  addChannelToActiveSpace,
  clearSpaceWithNewChannel,
  launchSpace,
  updateSpaceThunk
} from "../../reducers/spacesSlice";

export default function ModalConfirmChannelAppend() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const modals = useSelector(state => state.modals);
  const {activeSpace, spaces} = useSelector(state => state.spaces);
  const channelsById = useSelector(state => state.channels);
  const {show, data} = modals[MODAL_IDS.MODAL_CONFIRM_APPEND] || {};
  useKeyPressEvent((event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      close();
    }
  });

  const channelExistsInActiveSpace = useMemo(() => {
    return activeSpace.channels.some(channelId => channelId === data?.channelId);
  }, [activeSpace.id, data?.channelId]);

  function close() {
    dispatch(hideModal(MODAL_IDS.MODAL_CONFIRM_APPEND));
  }

  function onConfirm() {
    dispatch(addChannelToActiveSpace(data.channelId));
    if (activeSpace.id) dispatch(updateSpaceThunk({...activeSpace, channels: [data.channelId, ...activeSpace.channels]}));
    dispatch(hideModal(MODAL_IDS.MODAL_CONFIRM_APPEND));
  }

  function onAddToNewSpace() {
    dispatch(clearSpaceWithNewChannel(data.channelId));
    dispatch(hideModal(MODAL_IDS.MODAL_CONFIRM_APPEND));
  }

  function launchEntireSpace() {
    dispatch(launchSpace(data.spaceId));
    dispatch(hideModal(MODAL_IDS.MODAL_CONFIRM_APPEND));
  }

  if (!show) return null;

  const channel = channelsById[data.channelId];
  const space = spaces.find(({id}) => id === data.spaceId);
  const isSpaceManaged = space ? space.managed : true;

  return (
    <div ref={modalRef} className="absolute z-[50] h-full w-full flex flex-col justify-center items-center bg-gray-800/50">
      <div className="bg-gray-900 m-5 p-5 rounded-lg ring-2 ring-white">
        <div className="flex items-center justify-between mb-4">
          <div className="font-semibold">Open to...</div>
          <XCircleIcon className="h-7 w-7 cursor-pointer" onClick={close} />
        </div>
        <div className="space-y-4">
          <Tooltip arrow position="right" title='This will add this channel to your currently active space.'>
            <button
              type="button"
              disabled={channelExistsInActiveSpace}
              className="flex justify-between items-center relative truncate w-full inline-flex justify-center items-center gap-x-1.5 rounded-md bg-blue-600 disabled:bg-gray-500 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={onConfirm}
            >
              Current Space
              <SquaresPlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>
          <Tooltip arrow position="right" title="This will create a new blank space that you can later name.">
            <button
              type="button"
              className="flex justify-between items-center mt-4 relative truncate w-full inline-flex justify-center items-center gap-x-1.5 rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              onClick={onAddToNewSpace}
            >
              New blank Space
              <Squares2X2Icon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>
          {
            !isSpaceManaged ? (
              <button
                type="button"
                className="flex justify-between items-center relative truncate w-full inline-flex justify-center items-center gap-x-1.5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={launchEntireSpace}
              >
                <span>Launch {space.name}</span>
                <RocketLaunchIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </button>
            ) : null
          }
        </div>
        <div className="mx-auto font-semibold text-gray-400 text-sm mt-5 pt-4 border-t border-gray-400">{channel.slug}</div>
        <div className="mx-auto italic text-gray-400 text-xs ">{channel.description}</div>
      </div>
    </div>
  )
}
