import {XCircleIcon} from "@heroicons/react/24/outline";
import {Fragment} from "react";
import { Dialog, Transition } from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {hideModal, MODAL_IDS} from "../reducers/modalsSlice";
import {ArchiveChannelForm} from "./SettingsForm";

export default function ModalArchiveChannel() {
  const modals = useSelector(state => state.modals);
  const {show: open, data: {channelId}} = modals[MODAL_IDS.MODAL_ARCHIVE_CHANNEL];
  const channel = useSelector(state => state.channels[channelId]);
  const dispatch = useDispatch();

  function close() {
    dispatch(hideModal(MODAL_IDS.MODAL_ARCHIVE_CHANNEL));
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-5">
                <Dialog.Title>
                  <div className="mb-5">
                    <div className="flex justify-between items-center">
                      <span className="font-bold text-red-500">Archive Channel</span>
                      <XCircleIcon className="h-5 w-5" onClick={close} />
                    </div>
                    <p className="my-5 text-sm font-semibold">This action is irreversible.</p>
                    <p className="my-5 text-sm font-semibold">No channel participant including yourself, will be able to send new messages, but, everyone will be able to view messages.</p>
                  </div>
                </Dialog.Title>
                <div>
                  {channelId && <ArchiveChannelForm close={close} channelId={channelId} name={channel?.slug} /> }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
