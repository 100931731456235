import {
  ArchiveBoxXMarkIcon,
  EyeIcon, EyeSlashIcon
} from "@heroicons/react/24/outline";
import {Fragment, useContext} from 'react'
import {Menu, Transition} from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import {useDispatch, useSelector} from "react-redux";
import {
  removeChannelFromActiveSpace,
  removeChannelsFromActiveSpace,
  removeSpaceThunk,
  updateSpaceThunk
} from "../reducers/spacesSlice";
import {classNames} from '../utils/classes';
import SortableTreeSpaceContext from "./SortableTreeSpaceContext";

export default function SpaceItemMenuDropdown({space}) {
  const {showMutedChannelsBySpaceId, toggleMutedChannelsBySpaceId, hasMutedChannelsToShow} = useContext(SortableTreeSpaceContext);
  const channelsById = useSelector((state) => state.channels);
  const {activeSpace} = useSelector((state) => state.spaces);
  const dispatch = useDispatch();

  const showingMutedChannels = showMutedChannelsBySpaceId[space.id];

  async function removeArchivedChannelsFromSpace() {
    const nonArchivedChannels = space.channels.filter(chId => !channelsById[chId].archived);
    await dispatch(updateSpaceThunk({...space, channels: nonArchivedChannels}));

    const archivedChannelIds = space.channels.filter(chId => channelsById[chId].archived);

    if (space.id === activeSpace.id) dispatch(removeChannelsFromActiveSpace(archivedChannelIds));
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex w-full justify-center rounded-md text-sm font-semibold text-gray-900 shadow-sm">
          <EllipsisVerticalIcon className="-mr-1 h-5 w-5 text-gray-200" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-700 rounded-md backdrop-blur-md bg-opacity-[40%] bg-gray-900 shadow-gray-700/50 ring-2 ring-gray-500 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => hasMutedChannelsToShow && toggleMutedChannelsBySpaceId(space.id)}
                  className={classNames(
                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                    'group flex items-center px-4 py-2 text-sm space-x-2'
                  )}
                >
                  { showingMutedChannels ? <EyeSlashIcon className="h-5 w-5"/> : <EyeIcon className="h-5 w-5"/>}
                  {
                    hasMutedChannelsToShow ? (
                      <span>{showingMutedChannels ? 'Hide' : 'Show'} Muted Channel</span>
                    ) : (
                      <span className="italic">No muted channels</span>
                    )
                  }
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                    'group flex items-center px-4 py-2 text-sm space-x-2'
                  )}
                  onClick={removeArchivedChannelsFromSpace}
                >
                  <ArchiveBoxXMarkIcon className="h-5 w-5" />
                  <span>Remove archived channels</span>
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-200 text-red-500' : 'text-gray-300',
                    'group flex items-center px-4 py-2 text-sm space-x-2'
                  )}
                  onClick={() => dispatch(removeSpaceThunk(space))}
                >
                  <ArchiveBoxXMarkIcon className="h-5 w-5" />
                  <span>Delete Space</span>
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
