import {useState, useMemo} from 'react';
import {classNames} from '../utils/classes';
import {defaultStyles, FileIcon} from "react-file-icon";
import {ArrowDownOnSquareIcon} from "@heroicons/react/24/outline/index.js";

// These mime types will be considered "images" and they will be displayed
const IMAGE_MIME_TYPES = {
  'image/png': true,
  'image/jpg': true,
  'image/jpeg': true,
  'image/gif': true,
  'image/webp': true,
};

export default function MessageAttachmentViewer({attachments = []}) {
  const [enlarge, setEnlarge] = useState(null);

  const attachmentsByType = useMemo(() => {
    if (!attachments) return;

    return attachments.reduce((acc, att) => {
      if (IMAGE_MIME_TYPES[att.mime_type]) {
        acc.images.push(att);
        return acc;
      }

      acc.others.push(att);
      return acc;
    }, {images: [], others: []});
  }, [attachments]);

  if (!attachmentsByType || !attachments.length) return null;

  return (
    <>
      <div className="flex gap-1 flex-wrap">
        {
          attachmentsByType.images.map((attachment) => {
            if (enlarge && enlarge !== attachment.uuid) return null;

            const downloadUri = `/api/media/${attachment.uuid}`;

            return (
              <div
                onClick={() => setEnlarge(enlarge ? null : attachment.uuid)}
                key={`message_attachment_${attachment.uuid}`}
                className={
                  classNames(
                    enlarge ? "bg-contain bg-center w-full h-96 border-2 border-white cursor-zoom-out" : "cursor-zoom-in bg-cover w-[30%] h-24",
                    'bg-no-repeat rounded-md bg-center relative bg-gray-900'
                  )
                }
                style={{backgroundImage: `url(${downloadUri})`}}
              >
                {
                  enlarge && (
                    <a download target="_blank" href={downloadUri} className="absolute top-0 right-0 mr-2 mt-2 bg-gray-800 shadow-sm rounded-md p-1 py-1.5">
                      <ArrowDownOnSquareIcon className="w-6 -mt-1" />
                    </a>
                  )
                }
              </div>
            )
          })
        }
      </div>
      <div className="mt-2">
        {
          attachmentsByType.others.map(({filename, uuid, mime_type}) => {
            const mimeTypeSuffix = mime_type?.split('/')?.pop();

            return (
              <div key={`message_attachment_${uuid}`} className="flex items-center space-x-1" onClick={() => window.open(`/api/media/${uuid}`, '_blank')}>
                <div className="w-4">
                  <FileIcon extension={mime_type} {...defaultStyles[mimeTypeSuffix || mime_type]} />
                </div>
                <a target="_blank" className="text-white truncate underline">{filename}</a>
              </div>
            )
          })
        }
      </div>
    </>
  )
}
