import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import connect from "../socket/socket";
import {setChannelMembers} from "./channelsSlice";

const initialState = {};

export const getChannelMembersThunk = createAsyncThunk(
  'channels/getChannelsMembers',
  async ({channelId}, thunkAPI) => {
    const socket = connect();
    const {success, members, invitedMembers} = await socket.emitWithAck('channel:members', {channelId});

    if (!success) return;

    thunkAPI.dispatch(setMembers({members, invitedMembers}));
    thunkAPI.dispatch(setChannelMembers({channelId, members, invitedMembers}));
  }
);

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    updateUserMemberStatus(state, action) {
      const {userId} = action.payload;
      if (!state[userId]) return;
      state[userId] = {...state[userId], ...action.payload};
    },
    setMembers(state, action) {
      const {members, invitedMembers} = action.payload;

      const reducedMembers = members.reduce((acc, member) => {
        acc[member.user_id] = member;
        return acc;
      }, {});

      return {
        ...state,
        ...reducedMembers,
        invitedMembers,
      };
    }
  }
});

export const {
  setMembers,
  updateUserMemberStatus,
} = membersSlice.actions;

export default membersSlice.reducer;
