import { Fragment, useRef, useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Dialog, Transition } from '@headlessui/react'
import {BuildingOffice2Icon, LinkIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import {Tooltip} from "react-tippy";
import {toast} from "react-toastify";
import {useClickAway} from "react-use";
import useSocket from "../hooks/useSocket";

import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import beamsImage from '../assets/orange-beams.png';
import {setUser} from "../reducers/userSlice";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

function CreateNewCompanyOption({onClick}) {
  return (
    <Combobox.Option
      className={({active}) =>
        `relative cursor-default select-none py-2 px-4 ${
          active ? 'bg-orange-500/50 text-white' : 'text-white'
        }`
      }
      onClick={onClick}
      value={''}
    >
      {({active}) => (
        <>
          <span
            className={`block truncate flex items-center space-x-5 ${
              active ? 'font-medium' : 'font-normal'
            }`}
          >
            <p>Create a new company?</p>
          </span>
        </>
      )}
    </Combobox.Option>
  )
}

function AutocompleteCompany({setSelected, setShowCreateNewCompany}) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [socket] = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.emit('companies:search', {query}, setResults);
  }, [socket, query]);

  return (
    <div className="border border-white rounded-md">
      <Combobox onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="bg-gray-800 w-full border-none pt-1 pb-2 pl-3 pr-5 text-sm leading-5 text-white focus:ring-0"
              placeholder="Type a cashtag or company name..."
              displayValue={(company) => company ? company.name : null}
              onChange={(event) => {
                setSelected(event.target)
                setQuery(event.target.value);
              }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options  className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 divide-y divide-gray-500 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {results.length === 0 || !query.length ? (
                <div
                  className="relative cursor-default select-none py-2 px-4 text-white"
                >
                  {
                    results.length === 0 && query ? (
                      <>
                        <div>
                          {
                            'Nothing found'
                          }
                        </div>
                      </>
                    ) : (
                      <span className="animate-pulse">Search by company name or $cashtag</span>
                    )
                  }
                </div>
              ) : (
                results.map((company) => {
                  return (
                    <Combobox.Option
                      key={company.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-orange-500/50 text-white' : 'text-white'
                        }`
                      }
                      value={company}
                    >
                      {({selected, active}) => (
                        <>
                        <span
                          className={`block truncate flex items-center space-x-5 ${
                            active ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <img src={company.avatar_url} className="h-10 w-10 rounded-lg object-contain" alt={`${company.name}'s profile picture`} />
                          <div>
                            <p>{company.name}</p>
                            <p className="text-gray-200 text-xs">${company.cashtag}</p>
                          </div>
                        </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  )
                })
              )}
              <CreateNewCompanyOption onClick={() => setShowCreateNewCompany(true)} />
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

function PublicBadge() {
  return (<span className="text-xs text-gray-500">[Public]</span>);
}

function upload(url, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      xhr.status === 200 ? resolve() : reject();
    };

    xhr.open('PUT', url);
    xhr.send(file);
  });
}

function OverlayCreateCompany({onCreated, onCancel}) {
  const createCompanyModalRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [companyData, setCompanyData] = useState({
    name: null,
    bio: null,
    cashtag: null,
  });
  const editCompanyAvatarFileInput = useRef(null);
  const [companyAvatar, setCompanyAvatar] = useState(null);
  const [companyAvatarFile, setCompanyAvatarFile] = useState(null);
  const [socket] = useSocket();
  useClickAway(createCompanyModalRef, onCancel);

  function previewCompanyAvatar(file) {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      setCompanyAvatarFile(file);
      setCompanyAvatar(e.target.result);
    }

    fileReader.readAsDataURL(file);
  }

  async function saveCompany(event) {
    event.preventDefault();

    if (!createCompanyModalRef.current.reportValidity()) return;

    if (!companyAvatar) {
      setErrors({
        ...errors,
        avatar: 'You must include a company image.',
      });

      return;
    }

    const {url, key} = await socket.emitWithAck('company:avatar:put', null);
    await upload(url, companyAvatarFile);

    const {success: companyCreated, msg, company} = await socket.emitWithAck('company:create', {...companyData, avatarUrl: key});

    if (!companyCreated) {
      return toast.error(msg, DEFAULT_TOAST_CONFIG);
    }

    onCreated({
      ...company,
      avatar_url: key,
    });
  }

  return (
    <div className="w-full h-full flex items-center justify-center absolute bg-black/50 z-50">
      <form ref={createCompanyModalRef} className="bg-gray-800 w-full min-h-48 z-50 ring-2 ring-white shadow-md p-5" onSubmit={saveCompany}>
        <h2 className="font-bold leading-5">Create a new company</h2>
        <br />
        <div className="col-span-full">
          <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-100">
            Photo
          </label>
          <div className="mt-2 flex items-center gap-x-3">
            {
              !companyAvatar ? (
                <BuildingOffice2Icon className="h-12 w-12 text-gray-100" aria-hidden="true" />
              ) : (
                <img alt="Proposed company avatar" src={companyAvatar} className="h-12 w-12 object-contain" />
              )
            }
            <button
              type="button"
              onClick={() => editCompanyAvatarFileInput.current.click()}
              className="rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-orange-500 hover:text-orange-500"
            >
              Change
            </button>
            <input onChange={(event) => {
              console.log('Input changed', event, event.target.files);
              previewCompanyAvatar(event.target.files[0]);
            }} type="file" ref={editCompanyAvatarFileInput} className="hidden" accept="image/jpg, image/jpeg, image/png" />
          </div>
          { errors.avatar ? <div className="text-red-500 text-xs">{errors.avatar}</div> : null }
        </div>
        <br />
        <div className="flex justify-between space-x-2">
          <div className="w-full">
            <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-100">
              Name
            </label>
            <input
              required
              id="company_name"
              name="company_name"
              type="company_name"
              onChange={({target: {value: name}}) => setCompanyData({...companyData, name})}
              defaultValue={companyData.name}
              autoComplete={false}
              placeholder="Name of you company..."
              className="block w-full rounded-md border-0 px-1.5 py-1.5 bg-gray-800 text-gray-100 placeholder:text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="w-full">
            <label htmlFor="cashtag" className="block text-sm font-medium leading-6 text-gray-100">
              Cashtag
            </label>
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
              <span className="flex select-none items-center pl-3 text-gray-100 sm:text-sm">$</span>
              <input
                required
                type="text"
                name="cashtag"
                id="cashtag"
                autoComplete="cashtag"
                onChange={({target: {value: cashtag}}) => setCompanyData({...companyData, cashtag})}
                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-100 placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="reinsuranceforyou"
              />
            </div>
          </div>
        </div>
        <br />
        <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-100">
          Website
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 w-full">
          <span className="flex select-none items-center px-2 text-gray-100 sm:text-sm"><LinkIcon className="h-4 w-4" /></span>
          <input
            type="url"
            name="website"
            id="website"
            autoComplete="website"
            onChange={({target: {value: website}}) => setCompanyData({...companyData, website})}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-100 placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="https://my-reinsurance-company-website.com"
          />
        </div>
        <br />
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
          Bio
        </label>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600">
          <textarea
            required
            placeholder="Give a short description of your company!"
            minLength="10"
            onChange={({target: {value: bio}}) => setCompanyData({...companyData, bio})}
            className="bg-transparent w-full outline-none border-none">
          </textarea>
        </div>
        <br />
        <div className="flex justify-between">
          <button onClick={onCancel} type="button" className="text-sm font-semibold leading-6 text-gray-100">
            Cancel
          </button>
          <button
            onClick={saveCompany}
            className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  )
}

function CompanyAvatar({avatarUrl, cashtag, classes = ''}) {
  if (!avatarUrl) return null;

  return (
    <Tooltip title={`$${cashtag}`} className="w-7 h-7 rounded-lg overflow-hidden inline-block">
      <img alt="Company avatar" src={avatarUrl} className={`w-full h-full bg-white object-contain ${classes}`} />
    </Tooltip>
  );
}

function UserAvatarForm({user, onSave}) {
  const editAvatarFileInput = useRef(null);
  const [editAvatarOverlay, setEditAvatarOverlay] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(user.avatar_url);
  const [socket] = useSocket();

  function editAvatar() {
    editAvatarFileInput.current.click();
  }

  async function uploadUserAvatar({target: {files}}) {
    const {url, key} = await socket.emitWithAck('user:avatar:put', null);
    const uploadResults = await upload(url, files[0]);
    console.log('Uploading', files, key);
    setAvatarUrl(key);
    onSave(key);
  }

  return (
    <>
      <div className="flex items-center space-x-2 mb-2">
        <p className="text-2xl pl-[11rem] font-bold">@{user.username}</p>
        <CompanyAvatar avatarUrl={user.company_avatar_url} cashtag={user.cashtag} />
      </div>
      <input type="file" ref={editAvatarFileInput} onChange={uploadUserAvatar} className="hidden" accept="image/jpg, image/jpeg, image/png" />
      { editAvatarOverlay ? (
        <div onClick={() => editAvatar()} onMouseOut={() => setEditAvatarOverlay(false)} className="absolute -bottom-12 left-7 h-32 w-32 rounded-full bg-gray-700/50 z-10 flex flex-col items-center justify-center">
          <PencilSquareIcon className="h-7 w-7 text-white pointer-events-none" />
        </div>
      ) : null }
      <img onMouseOver={() => setEditAvatarOverlay(true)} src={avatarUrl} className="absolute -bottom-12 left-7 h-32 w-32 rounded-full ring-4 ring-white object-cover" />
    </>
  )
}

export default function UserProfile({open, setOpen}) {
  const {user} = useSelector(state => state.user);
  const cancelButtonRef = useRef(null);
  const [jobRole, setJobRole] = useState();
  const [username, setUsername] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [editCompany, setEditCompany] = useState(false);
  const [company, setCompany] = useState();
  const [showCreateNewCompany, setShowCreateNewCompany] = useState(false);
  const [socket] = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setUsername(user.username);
    setJobRole(user.job_role);
    setAvatarUrl(user.avatar_url);
    setCompany(getUserCompanyData());
    setEditCompany(!user.company_id);
  }, [user]);

  function getUserCompanyData() {
    return ({
      cashtag: user.cashtag,
      name: user.company_name,
      avatar_url: user.company_avatar_url,
    });
  }

  function updateUser() {
    socket.emit('user:profile:update', {
      username,
      jobRole,
      firstName,
      lastName,
      avatarUrl,
      companyId: company.id,
    }, ({success, msg}) => {
      if (!success) return toast.error(msg, DEFAULT_TOAST_CONFIG);

      socket.emit('me', null, (me) => dispatch(setUser(me)));

      toast.success('You profile has been saved and updated.', DEFAULT_TOAST_CONFIG);
      setOpen(false);
    });
  }

  if (!user || !user.username) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                {
                  showCreateNewCompany ? (
                    <OverlayCreateCompany
                      onCancel={() => setShowCreateNewCompany(false)}
                      onCreated={(company) => {
                        setCompany(company);
                        setEditCompany(false);
                        setShowCreateNewCompany(false);
                      }}
                    />
                  ) : null
                }
                <div style={{backgroundImage: `url(${beamsImage})`}} className="h-32 bg-gray-800 border-b-4 border-white flex flex-col justify-end relative mb-10">
                  <UserAvatarForm onSave={(avatarUrl) => setAvatarUrl(avatarUrl) } user={user} />
                </div>
                <div className="space-y-12 p-5">
                  <div className="border-b border-gray-600 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-100">Profile</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-100">
                      This information will be displayed publicly so be careful what you share.
                    </p>

                    <div className="mt-10 flex items-center space-x-5">
                      <div className="w-full">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            disabled
                            id="email"
                            name="email"
                            type="email"
                            defaultValue={user.email}
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 bg-gray-800 text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="w-full">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-100">
                          Username <PublicBadge />
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
                            <span className="flex select-none items-center pl-3 text-gray-100 sm:text-sm">@</span>
                            <input
                              type="text"
                              name="username"
                              id="username"
                              value={username}
                              onChange={({target: {value}}) => setUsername(value)}
                              autoComplete="username"
                              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-100 placeholder:text-gray-100 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="janesmith"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-white/20 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-100">Professional Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-100">This information could be displayed publicly so be careful what you share.</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-100">
                          Job Role <PublicBadge />
                        </label>
                        <div className="mt-2">
                          <select
                            onChange={({target: {value}}) => setJobRole(value)}
                            id="job_role"
                            name="job_role"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white bg-gray-800 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-600 sm:text-sm sm:leading-6"
                            placeholder="Job Role"
                            value={jobRole || 'default'}
                          >
                            <option value="default" disabled>Select your job title...</option>
                            <option value='Reinsurance - UW'>Reinsurance - UW</option>
                            <option value='E&S - UW'>E&S - UW</option>
                            <option value='Insurance - UW'>Insurance - UW</option>
                            <option value='Insured'>Insured</option>
                            <option value='Reinsurance - Broker'>Reinsurance - Broker</option>
                            <option value='Wholesale - Broker'>Wholesale - Broker</option>
                            <option value='Retail - Broker'>Retail - Broker</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-100">
                          <span>Company</span>
                          <span onClick={() => {
                            setEditCompany(!editCompany);
                            if (!!editCompany) setCompany(getUserCompanyData());
                          }} className="text-xs text-gray-400 cursor-pointer ml-2">{editCompany ? 'Cancel' : 'Change'}</span>
                        </label>
                        <div className="mt-2">
                          { editCompany ? (
                            <AutocompleteCompany setSelected={(company) => setCompany(company)} setShowCreateNewCompany={setShowCreateNewCompany} />
                          ) : (
                            <div className="flex space-x-2 items-center">
                              <CompanyAvatar avatarUrl={company?.avatar_url} cashtag={company?.cashtag} />
                              <div className="font-semibold">{company?.name}</div>
                            </div>
                          ) }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-100">Personal Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-100">This information could be displayed publicly so be careful what you share.</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-100">
                          First name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            value={firstName}
                            onChange={({target: {value}}) => setFirstName(value)}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-100 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-100">
                          Last name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            value={lastName}
                            onChange={({target: {value}}) => setLastName(value)}
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 bg-gray-800 text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button onClick={() => setOpen(false)} type="button" className="text-sm font-semibold leading-6 text-gray-100">
                      Cancel
                    </button>
                    <button
                      onClick={() => updateUser()}
                      className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
