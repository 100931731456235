import DateFormatter, {DaysAreDifferent} from "../utils/DateFormatter.jsx";

export default function MessageDateSeparator({message, prevMessage}) {
  return (
    <DaysAreDifferent
      key={`${message.id}_${message.inserted_at}`}
      start={message.inserted_at}
      end={prevMessage?.inserted_at}
    >
      <div className="text-xs text-center py-1">
        <DateFormatter options={{
          weekday: "long",
          month: "long",
          day: "numeric",
        }} dateString={message.inserted_at} />
      </div>
    </DaysAreDifferent>
  )
}
