import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  user: {
    avatar_url: null,
    email: null,
    id: null,
    status: null,
    status_updated_at: null,
    username: null,
  },
  socketId: null,
  server: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      console.log('🤷‍♂️Setting user...', {state, action});
      return action.payload;
    }
  }
});

export const {
  setUser,
} = userSlice.actions;

export default userSlice.reducer;
