import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {Fragment, useEffect, useState} from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import validator from 'email-validator';
import useSocket from "../hooks/useSocket";
import AutocompleteMembers from "../modals/AutocompleteMembers";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import {appendChannelToActiveSpace, launchSpace} from "../reducers/spacesSlice";
import {useIsAModalOpen} from "../selectors/modalSelectors";
import Spinner from "../Spinner";
import {classNames} from "../utils/classes";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

function NewChannelForm({setOpen}) {
  const [socket] = useSocket();

  const [channelName, setChannelName] = useState('');
  const [channelDescription, setChannelDescription] = useState('');
  const [errors, setErrors] = useState([]);
  const [invitedUsers, addToInvitedUsers] = useState([]);
  const [invitedEmails, addToInvitedEmails] = useState({});
  const [channelNameIsValid, setChannelNameIsValid] = useState(false);
  const [channelCreationStatus, setChannelCreationStatus] = useState(false);
  const [awaitAddToSpace, setAwaitAddToSpace] = useState(null);
  const {spaces, activeSpace} = useSelector(state => state.spaces);
  const unmanagedSpaces = useSelector(state => state.spaces.spaces.filter(space => !space.managed).map((space) => ({
    id: space.id,
    name: space.name,
    description: ''
  })));
  const [spaceToAdd, setSpaceToAdd] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    validateName();
  }, [channelName]);

  useEffect(() => {
    const targetSpace = unmanagedSpaces.find(space => space.id === activeSpace?.id);

    if (targetSpace) setSpaceToAdd(targetSpace.id);
  }, [activeSpace]);

  useEffect(() => {
    if (!awaitAddToSpace) return;

    const targetSpace = spaces.find(({id}) => id === spaceToAdd);
    if (activeSpace && activeSpace.id === targetSpace.id) {
      if (targetSpace.channels.includes(awaitAddToSpace)) {
        dispatch(launchSpace(targetSpace.id));
      }

      if (activeSpace.channels.includes(awaitAddToSpace)) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  }, [awaitAddToSpace, spaces, spaceToAdd, activeSpace]);

  function validateName() {
    socket && socket.emit('channel:validate_name', {validName: channelName}, ({valid}) => {
      setChannelNameIsValid(!!valid);
    });
  }

  function createRoomAndClose() {
    setChannelCreationStatus('saving');
    socket.emit('new_channel', {channelName, channelDescription, invitedUsers, invitedEmails, spaceToAdd}, ({channel, success, msg, errors}) => {
      if (!success) {
        if (errors) setErrors(errors);
        if (!errors) toast.error(msg || 'Channel could not be created.', DEFAULT_TOAST_CONFIG);
        setChannelCreationStatus(null);
        return;
      }

      setChannelCreationStatus('saved');
      toast.success(`Channel created!${!spaceToAdd ? ' Click to open in Space.' : ''}`, {
        ...DEFAULT_TOAST_CONFIG,
        onClick: () => {
          dispatch(appendChannelToActiveSpace(channel.id));
        },
      });

      if (spaceToAdd) {
        setAwaitAddToSpace(channel.id);
      } else {
        setOpen(false);
      }
    });
  }

  function createNewSpace() {
    dispatch(showModal({modalId: MODAL_IDS.MODAL_NEW_BLANK_SPACE}));
  }

  const hasValidName = channelNameIsValid && channelName;
  const invitedEmailsList = Object.keys(invitedEmails);

  return (
    <div className="pb-16">
      <div className="mt-1">
        <p className="text-sm text-white">
          Create a new channel with you and all your friends!
        </p>
      </div>
      {
        errors.length ? (
          <ul className="bg-red-800/75 ring-2 ring-red-600 text-white text-sm font-bold rounded-lg p-3 pl-8 mt-5 list-disc">
            {
              errors.map((err) => {
                return (<li className="dec">{err}</li>)
              })
            }
          </ul>
        ) : null
      }
      <div className="mt-5">
        <fieldset className={classNames(!channelCreationStatus ? '' : 'opacity-50 pointer-events-none', 'rounded-lg border border-white px-3 p-3')}>
          <legend className="text-xs font-bold px-1.5 flex items-center space-x-2">
            <span>Channel Details</span>
            {channelCreationStatus === 'saving' ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" /> : null }
            {channelCreationStatus === 'saved' ? <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" /> : null }
          </legend>
          <div className="flex justify-between">
            <label htmlFor="channel_name" className="block text-sm font-medium leading-6 text-white">
              Name
            </label>
          </div>
          <div className="mt-2 relative">
            <input
              type="text"
              name="channel_name"
              id="channel_name"
              onChange={({target: {value}}) => setChannelName(value)}
              className="bg-transparent block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
              placeholder="Your channel name..."
              aria-describedby="text-optional"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              { channelName ? (
                hasValidName ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                ) : (
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                )
              ) : null }
            </div>
          </div>
          <br />
          <div className="flex justify-between">
            <label htmlFor="channel_description" className="block text-sm font-medium leading-6 text-white">
              Description
            </label>
          </div>
          <div className="mt-2 relative">
            <input
              type="text"
              maxLength={255}
              name="channel_description"
              id="channel_description"
              onChange={({target: {value}}) => setChannelDescription(value)}
              className="bg-transparent block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
              placeholder="Describe your channel..."
              aria-describedby="text-optional"
            />
          </div>
          <br />
          <label className="block text-sm font-medium text-white text-left">Invitees</label>
          {!invitedUsers.length && !invitedEmailsList.length ? (
            <p className="italic text-gray-300 text-center text-sm my-2 mb-5">No invitees yet</p>
          ) : null}
          <div className="mb-5 mt-1 space-y-2">
            <label className="block text-xs font-medium text-white text-left">Users</label>
            <div className={classNames(!invitedUsers.length ? 'border-dashed' : null, "border-2 border-gray-400 rounded-lg divide-y-2 divide-gray-400")}>
              {!invitedUsers.length ? <p className="italic text-gray-300 p-2 text-sm">No users added</p> : null}
              {
                invitedUsers.map((user) => {
                  return (
                    <div key={`selected_invitee_${user.id}`} className="flex text-white justify-between items-center shadow-sm px-3 py-1">
                      <div className="flex items-center space-x-4">
                        <img src={user.avatar_url} alt={`${user.username} profile pic`} className="h-5 w-5 rounded-full shadow-md" />
                        <span className="truncate">{user.username}</span>
                      </div>
                      <XMarkIcon className="text-white h-5 w-5 hover:text-red-700 cursor-pointer" onClick={() => {
                        addToInvitedUsers(invitedUsers.filter(({id}) => id !== user.id));
                      }} />
                    </div>
                  )
                })
              }
            </div>
            <label className="block text-xs font-medium text-white text-left">Email Addresses [Optional]</label>
            <div className={classNames(!invitedEmailsList.length ? 'border-dashed' : null, "border-2 border-gray-400 rounded-lg divide-y-2 divide-gray-400")}>
              {!invitedEmailsList.length ? <p className="italic text-gray-300 p-2 text-sm">No emails added</p> : null}
              {
                invitedEmailsList.map((email) => {
                  return (
                    <div key={`selected_invitee_${email}`} className="flex text-white justify-between items-center shadow-sm px-3 py-1 inline">
                      <div className="flex items-center space-x-4">
                        <span className="truncate">{email}</span>
                      </div>
                      <XMarkIcon className="text-white h-5 w-5 hover:text-red-700 cursor-pointer" onClick={() => {
                        const newInvitedEmail = {...invitedEmails};
                        delete newInvitedEmail[email];
                        addToInvitedEmails(newInvitedEmail);
                      }} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="h-[1px] mt-4 mb-3 bg-white"></div>
          <label className="block text-sm font-medium text-white text-left">Add users</label>
          <AutocompleteMembers
            exclude={invitedUsers}
            setSelected={(selected) => {
              addToInvitedUsers([...invitedUsers, selected]);
            }}
            validateUnknown={(query) => {
              return validator.validate(query);
            }}
            selectUnknownMember={(unknownEmail) => {
              addToInvitedEmails({...invitedEmails, [unknownEmail]: unknownEmail});
            }}
          />
        </fieldset>
        <br />
        <fieldset className={classNames(!channelCreationStatus ? '' : 'opacity-50 pointer-events-none', 'rounded-lg border border-white px-3 p-3')}>
          <legend className="text-xs font-bold px-1.5 flex items-center space-x-2">
            <span>Your details</span>
            {['saving', 'saved'].includes(channelCreationStatus) ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" /> : null }
          </legend>
          <div className="flex items-center justify-between text-xs">
            <div>
              <label className="block flex items-center text-sm font-medium text-white text-left">
                <span>Choose a Space</span>
                <span className="text-white text-xs font-light italic ml-1">[Optional]</span>
              </label>
              <p className="mb-1 text-xs text-gray-300">Pick a space to add you new channel to...</p>
            </div>
            <a className="mb-5 text-sm hover:text-blue-500" onClick={createNewSpace}>Create a Space</a>
          </div>
          {
            !unmanagedSpaces.length ? (
              <>
                <div className="text-sm italic text-center my-5">You don't have any spaces.</div>
                <div className="text-sm italic text-center my-5">
                  <span>Try adding one? </span>
                  <a className="mb-5 not-italic text-sm hover:text-blue-500" onClick={createNewSpace}>Create a Space</a>
                </div>
              </>
            ) : (
              <RadioGroup value={spaceToAdd} className="mt-3" onChange={setSpaceToAdd}>
                <RadioGroup.Label className="sr-only">Add to Space setting</RadioGroup.Label>
                <div className="-space-y-px rounded-md bg-gray-800">
                  {unmanagedSpaces.map((space, spaceIdx) => (
                    <RadioGroup.Option
                      key={space.name}
                      value={space.id}
                      className={({ checked }) =>
                        classNames(
                          spaceIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          spaceIdx === unmanagedSpaces.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'z-10 border-orange-200 bg-orange-100' : 'border-gray-200',
                          'relative flex cursor-pointer border p-4 focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                      <span
                        className={classNames(
                          checked ? 'bg-orange-600 border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-orange-600' : '',
                          'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col w-full">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(checked ? 'text-orange-900' : 'text-gray-200', 'flex justify-between items-center w-full block text-sm font-medium')}
                        >
                          <div>{space.name}</div>
                          { space.id === activeSpace?.id ? <div className="italic">Active Space</div> : '' }
                        </RadioGroup.Label>
                      </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            )
          }
        </fieldset>
      </div>
      <div className="z-30 absolute bottom-0 left-0 w-full p-3 px-4 flex justify-end items-center border-t border-gray-800 bg-slate-600">
        <button
          disabled={!channelNameIsValid}
          onClick={() => createRoomAndClose()}
          className="rounded-md disabled:bg-gray-500 disabled:opacity-50 bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
        >
          Create and Invite
        </button>
      </div>
    </div>
  );
}

export default function SlideoverNewChannel({open, setOpen}) {
  const [show, setShow] = useState(open);
  const modalIsShowing = useIsAModalOpen();

  useEffect(() => {
    if (open) setShow(true);
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setShow(false)}>
      <Dialog as="div" className="relative z-50" onClose={(val) => !modalIsShowing ? setOpen(val) : null}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-slate-700 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-white font-semibold leading-6 text-white">
                          Create a new channel
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-slate-700 text-white hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon onClick={() => setOpen(false)} className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {show ? <NewChannelForm setOpen={setOpen} /> : null}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
