import {Transition} from "@headlessui/react";
import {Fragment, useRef} from "react";
import {useClickAway} from "react-use";
import Spinner from "../Spinner";

export default function InfoSlideover({show, loading, close, onLeave, children}) {
  const slideoverRef = useRef(null);
  useClickAway(slideoverRef, close);

  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      afterLeave={onLeave}
    >
      <div ref={slideoverRef} className="w-3/4 xl:w-3/5 top-0 absolute z-40 right-0 overflow-y-auto h-full bg-gray-800 bg-opacity-50 backdrop-blur-md border-l border-gray-500 text-gray-100">
        {loading ? (
          <div className="h-full flex flex-col justify-center items-center">
            <div className="flex items-center">
              <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" />
              <span className="font-bold">Loading...</span>
            </div>
          </div>
        ) : null}
        {children}
      </div>
    </Transition>
  )
}
