import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useSocket, {useSocketEventWithFilter} from '../hooks/useSocket';

export default function ChannelReadCount({channel}) {
  const [socket, connected] = useSocket();
  const [unreadCount, setUnreadCount] = useState(null);
  const {user} = useSelector(state => state.user)

  useEffect(() => {
    if (!socket) return;

    getUnreadCount();
  }, [connected]);

  useSocketEventWithFilter('new_message', ['channel_id', channel?.id], (message) => {
    if (message.user_id === user.id || channel.lockToBottom) return;
    setUnreadCount((count) => count + 1);
  });

  useSocketEventWithFilter('read_update', ['channelId', channel?.id], () => {
    getUnreadCount();
  });

  function getUnreadCount() {
    socket.emit('channel:unread_count', {channelId: channel.id}, ({unreadCount}) => {
      setUnreadCount(Number.parseInt(unreadCount));
    });
  }

  if (!unreadCount) return null;

  return (
    <span className="rounded-full text-xs text-white bg-gray-800 border-2 border-gray-600 ml-2 px-1.5">
      {unreadCount > 0 && unreadCount < 10 ? unreadCount : '9+'}
    </span>
  );
}
