 export const DEFAULT_TOAST_CONFIG = {
  position: "bottom-center",
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 4000,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "dark",
}
