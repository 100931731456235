import {useCallback, useContext} from "react";
import {
  DndContext,
  pointerWithin,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove} from "@dnd-kit/sortable";
import {useDispatch} from "react-redux";
import UserContext from "../Contexts/UserContext";
import {setSwapChannelsInSpace} from '../reducers/spacesSlice';
import useSocket from "../hooks/useSocket";
import {findById} from "../utils/lookup";

export default function DndContextWrapper({children, setActiveId}) {
  const [socket] = useSocket();
  const dispatch = useDispatch();
  const {user: { state: userState }} = useContext(UserContext);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event) => {
    console.log('Dragging', event.active.id);
    setActiveId(event.active.id);
  }, []);

  const handleDragOver = useCallback((event) => {
    const {active, over} = event;

    if (active && over) {
      const [activeType] = active.id.split(':');
      const [overType] = over.id.split(':');

      console.log({activeType, overType});

      if (activeType === overType) {
        console.log('the types are the same, DROP', active, over);
      } else {
        console.log('the types are NOT the same, DON"T DROP');
      }
    }
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    const [activeType, activePosition, activeParentId, activeId] = active.id.split(':');
    const [overType, overPosition, overId, overParentId] = over?.id?.split(':') || [];

    console.log('Dropped!', activeType, overType, overPosition);

    if (activeType === overType) {
      if (active.id !== over?.id) {
        console.log('Types are the same and are movable', activeType);

        // Reorder channels in spaces
        if (activeType === 'tree') {
          if (activePosition === 'sub' && overPosition === 'sub') {
            setSpaceChannels((oldItems) => {
              const destinationParentIndex = oldItems.findIndex(({id}) => id === Number.parseInt(overParentId));
              const diffParents = activeParentId !== overParentId;
              if (diffParents) {
                const originalParentIndex = oldItems.findIndex(({id}) => id === Number.parseInt(activeParentId));
                oldItems[destinationParentIndex].channels.splice(over.id, 0, active.data.current.channel);
                oldItems[originalParentIndex].channels.splice(active.id, 1);
              } else {
                const oldIndex = oldItems[destinationParentIndex].channels.findIndex(({id}) => id === Number.parseInt(activeId));
                const newIndex = oldItems[destinationParentIndex].channels.findIndex(({id}) => id === Number.parseInt(overId));
                oldItems[destinationParentIndex].channels = arrayMove(oldItems[destinationParentIndex].channels, oldIndex, newIndex);
              }

              return [...oldItems];
            });
          }

          // reparent the channel
          if (activePosition === 'sub' && overPosition === 'parent') {
            console.log('STARTING POINT', activeParentId, over.data.current.parent.id);

            const oldItems = [...userState.spaces];

            const [, originalParentIndex] = findById(oldItems, 'id', activeParentId);
            const [, destinationParentIndex] = findById(oldItems, 'id', over.data.current.parent.id);

            if ([destinationParentIndex, originalParentIndex].includes(-1)) return;

            const foundItemInDest = oldItems[destinationParentIndex].channels.includes(activeId);

            if (!foundItemInDest) {
              oldItems[destinationParentIndex].channels = [active.data.current.channel.id, ...oldItems[destinationParentIndex].channels];
              oldItems[originalParentIndex].channels = oldItems[originalParentIndex].channels.filter((id) => id !== active.data.current.channel.id);
            }

            socket.emit('update_user_state', {
              partialState: {
                spaces: oldItems
              }
            }, (payload) => {
              console.log('Updating payload', payload);
            });
          }
        }

        if (activeType === 'grid') {

          dispatch(setSwapChannelsInSpace({from: active.id, to: over.id}));
        }
      }
    }

    if (activeType === 'grid' && overType === 'tree' && overPosition === 'parent') {
      const oldItems = userState.spaces;
      const channelId = active.data.current.channel.id;

      const [, destinationParentIndex] = findById(oldItems, 'id', over.data.current.parent.id);

      if (oldItems[destinationParentIndex].channels.includes(channelId)) return;

      oldItems[destinationParentIndex].channels.unshift(channelId);

      socket.emit('update_user_state', {
        partialState: {
          spaces: oldItems,
        },
      }, (payload) => {
        console.log('Updating payload', payload);
      });
    }

    setActiveId(null);
  }, [socket, userState]);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      {children}
    </DndContext>
  )
}
