import {useContext, useRef} from "react";
import {useSelector} from "react-redux";
import ChannelContext from "../Contexts/ChannelContext";
import ChannelOverlay from "./ChannelOverlay";
import SettingsForm from "./SettingsForm";

export default function SettingsOverlay() {
  const {showSettings, setShowSettings, channelId} = useContext(ChannelContext);
  const channel = useSelector(state => state.channels[channelId]);

  function close() {
    setShowSettings(false);
  }

  if (!showSettings) return null;

  return (
    <ChannelOverlay>
      <SettingsForm channel={channel} onClose={close} />
    </ChannelOverlay>
  )
}
