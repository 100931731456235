import {AcademicCapIcon} from "@heroicons/react/24/solid";
import {useContext, useEffect, useState} from "react";
import ChannelContext from "../Contexts/ChannelContext";
import useSocket from "../hooks/useSocket";
import {classNames} from "../utils/classes";
import InfoSlideover from "./InfoSlideover";

export default function UserInfoSlideover({show, userData: initUserData}) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {setShowUserInfoSlideover, setShowCompanyInfoSlideover, channelId} = useContext(ChannelContext);
  const [socket] = useSocket();

  useEffect(() => {
    if (!initUserData) return;

    setLoading(true);

    socket.emit('user:info', {userId: initUserData.id, channelId}, ({user}) => {
      setLoading(false);
      setUserData(user);
    });
  }, [initUserData]);

  function renderProfileData(data) {
    return (
      <h2 className={classNames(data ? '' : 'italic')}>{data ? data : 'Unlisted'}</h2>
    )
  }

  function close() {
    setShowUserInfoSlideover(false);
  }

  return (
    <InfoSlideover show={show} onLeave={() => setUserData(null)} loading={loading} close={close}>
      {
        userData ? (
          <div>
            <div className="flex items-center sticky top-0 backdrop-blur-md bg-transparent p-3 border-b border-gray-600">
              <div className="origin-top-left relative">
                <div className="ring-2 ring-white ml-2 mt-3 left-0 top-0 inline-block w-12 h-12 rounded-md flex-1 rounded-lg overflow-hidden">
                  <img src={userData?.avatar_url} alt="" className="object-contain bg-white" />
                </div>
              </div>
              <div className="ml-4 text-left">
                <h2 className="grow font-semibold pt-2">{userData.first_name} {userData.last_name}</h2>
                <h2 className="grow text-sm">@{userData.username}</h2>
              </div>
            </div>
            <div className="space-y-5 p-4">
              <div className="">
                <h2 className="font-semibold">Company Info</h2>
                <div className="flex items-center space-x-2 py-2" onClick={() => {
                  close();
                  setShowCompanyInfoSlideover({companyId: userData?.company_id});
                }}>
                  <img src={userData.company_avatar_url} className="ring-2 ring-white h-10 w-10 rounded-lg" />
                  <span className="font-semibold">{renderProfileData(userData.company_name)}</span>
                </div>
              </div>
              <div className="">
                <h2 className="font-semibold">Bio</h2>
                {renderProfileData(userData.bio)}
              </div>
              <div className="">
                <h2 className="font-semibold">Role</h2>
                <div className="capitalize">{renderProfileData(userData.job_role?.toLowerCase())}</div>
              </div>
              <div className="">
                <h2 className="font-semibold">Active</h2>
                <div className="capitalize">{renderProfileData(userData.status?.toLowerCase())}</div>
              </div>
              <div className="">
                <h2 className="font-semibold">Channel Status</h2>
                {renderProfileData(initUserData.isChannelAdmin ? (
                  <div className="flex items-center space-x-2">
                    <AcademicCapIcon className="h-5 text-yellow-500" />
                    <div>Admin</div>
                  </div>
                ) : 'Member')}
              </div>
            </div>
          </div>
        ) : null
      }
    </InfoSlideover>
  );
}
