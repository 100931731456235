import {useDispatch, useSelector} from "react-redux";
import {
  launchSpace, removeChannelFromActiveSpace,
  updateSpaceThunk
} from "../reducers/spacesSlice";

export function useDispatchRemoveChannelFromSpace() {
  const {spaces, activeSpace} = useSelector(state => state.spaces);
  const dispatch = useDispatch();

  return async ({channelId, spaceId}) => {
    const targetSpace = spaces.find(({id}) => id === spaceId);

    if (targetSpace) {
      const newChannels = targetSpace.channels.filter(chId => chId !== channelId);
      await dispatch(updateSpaceThunk({...targetSpace, channels: newChannels}));
    }

    if (activeSpace.id) {
      const newChannels = activeSpace.channels.filter(chId => chId !== channelId);
      await dispatch(updateSpaceThunk({...activeSpace, channels: newChannels}));
      dispatch(launchSpace(activeSpace.id));
    } else {
      dispatch(removeChannelFromActiveSpace(channelId));
    }
  }
}
