import {createSlice} from "@reduxjs/toolkit";

export const MODAL_IDS = {
  MODAL_CONFIRM_APPEND: 'confirmModalAppend',
  MODAL_NEW_BLANK_SPACE: 'createNewBlankSpace',
  MODAL_ARCHIVE_CHANNEL: 'archiveChannel',
  MODAL_TAGS_MANAGER: 'tagsManager',
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState: Object.values(MODAL_IDS).reduce((acc, modalKey) => {
    return {
      ...acc,
      [modalKey]: {
        show: false,
        data: {},
      }
    }
  }, {}),
  reducers: {
    showModal(state, action) {
      return {
        ...state,
        [action.payload.modalId]: {
          show: true,
          data: action.payload.data,
        },
      }
    },
    hideModal(state, action) {
      state[action.payload].show = false;
    },
  },
});

export const {
  showModal,
  hideModal
} = modalsSlice.actions;

export default modalsSlice.reducer;
