import {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useSocket from "./hooks/useSocket";
import {appendMessage, removeMessage, setIsTyping, updateMessage} from "./reducers/channelsSlice";
import {getChannelMembersThunk, updateUserMemberStatus} from "./reducers/membersSlice";
import {addTag, updateTag} from "./reducers/tagsSlice";
import {notify} from "./utils/Notify";

export default function EventManager() {
  const [socket, connected] = useSocket();
  const {user} = useSelector(state => state.user);
  const channels = useSelector(state => state.channels);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!socket || !connected) return;

    function onTyping(payload) {
      const {typing: username, isTyping: activelyTyping, channelId} = payload;

      const newIsTyping = {...activelyTyping};

      if (!activelyTyping) {
        delete newIsTyping[username];
      } else {
        newIsTyping[username] = true;
      }

      dispatch(setIsTyping({channelId, typing: Object.keys(newIsTyping || {}).join(', ')}))
    }

    function onNewMessage(payload) {
      const {channel_id: channelId, user_id: userId} = payload;

      if (userId !== user.id && !channels[channelId]?.muted) {
        notify({title: `[${channels[channelId].slug}] New message`, body: payload.message_plain});
      }

      dispatch(appendMessage({channelId, message: payload}));
    }

    function onUserStatusChanged(payload) {
      dispatch(updateUserMemberStatus(payload));
    }

    function onMembersUpdated({channel_id: channelId}) {
      dispatch(getChannelMembersThunk({channelId}));
    }

    function onChannelUpdated({channelId}) {
      dispatch(getChannelMembersThunk({channelId}));
    }

    function onTagUpdated(payload) {
      dispatch(updateTag(payload));
    }

    function onTagAdded(payload) {
      dispatch(addTag(payload));
    }

    function onMessageUpdate(payload) {
      dispatch(updateMessage(payload));
    }

    function onMessageDeleted(payload) {
      dispatch(removeMessage(payload));
    }

    socket.on('typing', onTyping);
    socket.on('new_message', onNewMessage);
    socket.on('user_status_change', onUserStatusChanged);
    socket.on('user:channels:updated', onChannelUpdated);
    socket.on('channel:members:updated', onMembersUpdated);
    socket.on('tag:updated', onTagUpdated);
    socket.on('tag:added', onTagAdded);
    socket.on('message:update', onMessageUpdate);
    socket.on('message:deleted', onMessageDeleted);

    return () => {
      socket.off('typing', onTyping);
      socket.off('new_message', onNewMessage);
      socket.off('user_status_change', onUserStatusChanged);
      socket.off('channel:members:updated', onMembersUpdated);
      socket.off('user:channels:updated', onChannelUpdated);
      socket.off('tag:updated', onTagUpdated);
      socket.off('tag:added', onTagAdded);
      socket.off('message:update', onMessageUpdate);
      socket.off('message:deleted', onMessageDeleted);
    }
  }, [socket, connected, user]);

  return (<Fragment />);
}
