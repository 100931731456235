import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {
  ArchiveBoxIcon, ArrowsUpDownIcon, BackspaceIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon, SpeakerWaveIcon, SpeakerXMarkIcon, TagIcon
} from "@heroicons/react/24/outline";
import {Fragment, useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "react-tippy";
import {toast} from "react-toastify";
import ChannelTag from "../Channel/ChannelTag";
import DashboardContext from "../Contexts/DashboardContext";
import {useDispatchRemoveChannelFromSpace} from "../hooks/useChannelDispatchers";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import {toggleMuteSpaceChannelThunk} from "../reducers/spacesSlice";
import {classNames} from "../utils/classes";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import {TagsManager} from "./ChannelTagsManager";
import SortableTreeSpaceContext from "./SortableTreeSpaceContext";
import SpaceBeaconPin from "./SpaceBeaconPin";

export default function ChannelItemMenuDropdown({channel, spaceId}) {
  const {setShowChannelSettingsModal, setShowArchiveChannelModal} = useContext(DashboardContext);
  const {setChangeChannelSpace} = useContext(SortableTreeSpaceContext);
  const dispatchRemoveChannelFromSpace = useDispatchRemoveChannelFromSpace();
  const space = useSelector(state => state.spaces.spaces.find(({id}) => id === spaceId));
  const dispatch = useDispatch();

  function showTagsManager() {
    dispatch(showModal({
      modalId: MODAL_IDS.MODAL_TAGS_MANAGER,
      data: {channelId: channel.id},
    }));
  }

  function muteChannel() {
    dispatch(toggleMuteSpaceChannelThunk({channelId: channel.id, spaceId}));
  }

  const isSpaceManaged = space.managed;

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 shadow-sm">
          <EllipsisVerticalIcon className="-mr-1 h-5 w-5 text-gray-200" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="overflow-hidden absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-700 rounded-md backdrop-blur-md bg-opacity-[40%] bg-gray-900 shadow-gray-700/50 ring-2 ring-gray-500 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={() => setShowChannelSettingsModal(channel.id)}
                    className={classNames(
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <Cog6ToothIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                    onClick={() => showTagsManager()}
                  >
                    <TagIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    <span>Manage Tags</span>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                <a
                  href="#"
                  className={classNames(
                    'text-gray-300',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                  onClick={() => showTagsManager()}
                >
                  <div className="flex gap-2 flex-wrap overflow-y-auto">
                    { channel.tags.map(tag => <ChannelTag fill tagId={tag.id} key={`channel_item_menu_dropdown_${channel.id}_${tag.id}`} /> ) }
                  </div>
                  {!channel.tags.length ? (
                    <div className="italic w-full text-gray-500 text-center">This channel has no tags.</div>
                  ) : null}
                </a>
              </Menu.Item>
            </div>
            {
              !channel.archived ? (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                          'group flex items-center px-4 py-2 text-sm flex justify-between'
                        )}
                        onClick={muteChannel}
                      >
                        <div className="flex justify-between items-center">
                          {
                            channel.muted ? (
                              <>
                                <SpeakerWaveIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                <span>Unmute Channel</span>
                              </>
                            ) : (
                              <>
                                <SpeakerXMarkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                <span>Mute Channel</span>
                              </>
                            )
                          }
                        </div>
                        <Tooltip position="right-end" title="When a channel is muted you will no longer receive notifications and it will be hidden from this Space.">
                          <QuestionMarkCircleIcon className="h-5 w-5" />
                        </Tooltip>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={() => dispatch(showModal({modalId: MODAL_IDS.MODAL_ARCHIVE_CHANNEL,  data: {channelId: channel.id}}))}
                        className={classNames(
                          active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                          'group flex justify-between items-center px-4 py-2 text-sm'
                        )}
                      >
                        <div className="flex justify-between items-center">
                          <ArchiveBoxIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          <span>Archive</span>
                        </div>
                        <Tooltip position="right-end" title="Archiving a channel will memorialize it. No participant including you will be able to send messages, but you can view all past messages and their attachments">
                          <QuestionMarkCircleIcon className="h-5 w-5" />
                        </Tooltip>
                      </a>
                    )}
                  </Menu.Item>
                </div>
              ) : null
            }
            <div>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => {
                      const toastId = toast.info((
                        <>
                          <p className="text-slate-200 leading-tight text-sm">Click on a Spaces pin to move the channel to that space.</p>
                          <p className="text-xs mt-2 text-slate-300">Click this notification to cancel.</p>
                        </>
                      ), {
                        ...DEFAULT_TOAST_CONFIG,
                        clickToClose: true,
                        autoClose: false,
                        onClick: () => {
                          setChangeChannelSpace(null);
                        },
                        icon: () => (<div className="scale-[2]"><SpaceBeaconPin /></div>)
                      });

                      setChangeChannelSpace({channelId: channel.id, fromSpaceId: spaceId, _toastId: toastId});
                    }}
                    className={classNames(
                      active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                      'group flex justify-between items-center px-4 py-2 text-sm'
                    )}
                  >
                    <div className="flex justify-between items-center">
                      <ArrowsUpDownIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <span>Change Space</span>
                    </div>
                    <Tooltip position="right-end" title="Removing a channel from it's Space will send the channel to the 'Other Channels' Space below.">
                      <QuestionMarkCircleIcon className="h-5 w-5" />
                    </Tooltip>
                  </a>
                )}
              </Menu.Item>
              {
                !isSpaceManaged ? (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={() => {
                          if (window.confirm('Are you sure you want to remove this channel from it\'s Space?')) {
                            dispatchRemoveChannelFromSpace({channelId: channel.id, spaceId});
                          }
                        }}
                        className={classNames(
                          active ? 'bg-gray-200 text-gray-900' : 'text-gray-300',
                          'group flex justify-between items-center px-4 py-2 text-sm'
                        )}
                      >
                        <div className="flex justify-between items-center">
                          <BackspaceIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          <span>Remove from Space</span>
                        </div>
                        <Tooltip position="right-end" title="Removing a channel from it's Space will send the channel to the 'Other Channels' Space below.">
                          <QuestionMarkCircleIcon className="h-5 w-5" />
                        </Tooltip>
                      </a>
                    )}
                  </Menu.Item>
                ) : null
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
