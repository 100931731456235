import {Dialog, Transition} from "@headlessui/react";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {Fragment, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideModal, MODAL_IDS} from "../reducers/modalsSlice";
import {clearSpace, saveSpaceThunk} from "../reducers/spacesSlice";

export default function ModalNewSpace() {
  const cancelButtonRef = useRef(null);
  const [spaceName, setSpaceName] = useState();
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);

  const open = modals[MODAL_IDS.MODAL_NEW_BLANK_SPACE]?.show || false;

  function save() {
    dispatch(clearSpace());
    dispatch(saveSpaceThunk({name: spaceName, channels: [], defaultMinimized: false}));
    close();
  }

  function close () {
    dispatch(hideModal(MODAL_IDS.MODAL_NEW_BLANK_SPACE));
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600/25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm p-5">
                <Dialog.Title>
                  <div className="flex justify-between items-center mb-5">
                    <span className="font-bold text-blue-500">New Space Name</span>
                    <XCircleIcon className="h-5 w-5" onClick={close} />
                  </div>
                </Dialog.Title>
                <div>
                  <p className="my-5 text-sm">Spaces are great! They can help you keep your channels organized.</p>
                  <input
                    type="text"
                    name="channel_name"
                    id="channel_name"
                    onChange={({target: {value}}) => setSpaceName(value)}
                    className="bg-transparent block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                    placeholder="Your Space name..."
                    aria-describedby="text-optional"
                  />
                  <div className="flex justify-end">
                    <button
                      disabled={!spaceName}
                      onClick={() => save()}
                      className="rounded-md disabled:bg-gray-500 disabled:opacity-50 bg-blue-500 px-3 py-2 mt-5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
