import {Menu, Transition} from "@headlessui/react";
import {BackspaceIcon, ChevronDownIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {Fragment, useContext} from "react";
import {useSelector} from "react-redux";
import ChannelContext from "../Contexts/ChannelContext";
import {useDispatchRemoveChannelFromSpace} from "../hooks/useChannelDispatchers";

export default function ChannelHeaderCloseMenuDropdown() {
  const {channelId} = useContext(ChannelContext);
  const {activeSpace} = useSelector(state => state.spaces);
  const dispatchRemoveChannelFromSpace = useDispatchRemoveChannelFromSpace();

  function confirmRemove() {
    dispatchRemoveChannelFromSpace({channelId, spaceId: activeSpace.id});
  }

  return (
    <Menu as="div" className="relative grow flex">
      <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-white border-0">
        <XCircleIcon className="h-7 text-gray-500 hover:text-gray-100 transition-all" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="divide-gray-600 divide-y absolute left-0 z-10 mt-7 w-64 origin-top-right rounded-md bg-slate-700 text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-hidden">
          <Menu.Item
            as="div"
            onClick={() => confirmRemove()}
            className="p-2 flex items-center space-x-2 hover:bg-red-500 hover:text-white transition-all"
          >
            <BackspaceIcon className="h-5 w-5" />
            <div className="font-semibold text-sm">Remove channel from Space</div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
