/*
 * Send desktop notification to the user but only if the browser or tab is backgrounded.
 */

import {BellAlertIcon, HandThumbDownIcon, HandThumbUpIcon} from "@heroicons/react/24/outline";
import React from "react";
import {toast} from "react-toastify";
import sizzleUrl from '../assets/sizzle.mp3';
import Spinner from "../Spinner";

export function notify({title, body, force = false}, callback = () => {}) {
  if (!('Notification' in window)) return;

  const hasActiveWindow = window.localStorage.getItem('isActive') === 'true';

  if (!hasActiveWindow || force) {
    if (window.Notification.permission === 'granted') {
      const notification = new window.Notification(title, {body, icon: '/logo-lines.png'});
      notification.onclick = () => {
        window.focus();
        callback();
      };
    }

    try {
      new Audio(sizzleUrl).play();
    } catch (e) {
      console.error('User has not interacted with page yet. Not playing audio.');
    }
  }
}

export function requestNotificationPermission() {
  if (!('Notification' in window)) return;

  if (window.Notification.permission === 'default') {
    const toastRequestId = toast.info('Click here to allow for Fuse Notifications!', {
      icon: (<BellAlertIcon className="h-5 w-5 text-gray-300" />),
      closeOnClick: false,
      onClick: () => {
        let content = ''
        let icon = <Spinner className="text-white h-8 w-8" />;
        toast.update(toastRequestId, {icon});

        _requestNotificationPermission(() => {
          switch (window.Notification.permission) {
            case "denied":
              icon = <HandThumbDownIcon className="text-red-500 h-8 w-8" />
              content = 'Bummer! You can enable this later in your browser\'s settings';
              break;
            case "granted":
              icon = <HandThumbUpIcon className="text-green-500 h-8 w-8" />
              content = 'Great! We\'ll let you know when you have a new message come through.';
              break;
            default:
              icon = `🤷‍♂️`;
              content = 'Hmmm... We\'ll ask again at a later date.';
          }

          toast.update(toastRequestId, {render: content, icon});
          setTimeout(() => toast.dismiss(toastRequestId), 4000);
        });
      }
    })
  }
}

function _requestNotificationPermission(callback) {
  if (!('Notification' in window)) return;

  if (window.Notification.permission === 'default') {
    // We need to ask the user for permission
    window.Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        notify({
          title: '🔔 Notifications enabled',
          body: 'We\'ll notify you of new messages!',
          force: true,
        });
      }
    }).finally(callback);
  }
}
