import {
  AcademicCapIcon,
  ArrowLeftOnRectangleIcon, ChevronDownIcon,
  Cog6ToothIcon,
  PencilSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import React, {Fragment, useContext, useState} from "react";
import {Menu, Dialog, Transition, Listbox} from "@headlessui/react";
import {Squares2X2Icon, XMarkIcon} from "@heroicons/react/24/outline/index.js";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@tippyjs/react";
import {useLocalStorage} from "react-use";
import DashboardContext from "../Contexts/DashboardContext";
import FuseLogoText from "../FuseLogoText";
import useSocket from "../hooks/useSocket";
import {MODAL_IDS, showModal} from "../reducers/modalsSlice";
import SortableTree from '../Tree/SortableTree';
import logoLines from '../assets/logo-lines.png';
import {classNames} from "../utils/classes";
import ModalConfirmChannelAppend from "./modals/ModalConfirmChannelAppend";
import UserProfile from "./UserProfile";

function SpacesStack() {
  const [socket] = useSocket();
  const {user} = useSelector(state => state.user);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const {draggableActiveId, setShowNewRoomModal, toggleShowSidebar} = useContext(DashboardContext);
  const [socketDebug, setSocketDebug] = useLocalStorage('socketDebug', false);
  const dispatch = useDispatch();

  function logout() {
    if (!window.confirm('Are you sure you want to logout?')) return;

    fetch('/api/logout', {method: 'POST'}).then(() => {
      socket.disconnect();
      window.location.href = '/login';
    }).finally(() => {
      window.localStorage.removeItem('activeSpace');
    });
  }

  return (
    <div className="flex grow flex-col gap-y-1.5 overflow-x-hidden overflow-y-auto bg-gray-800 ring-1 ring-white/5">
      <div className="z-50 flex h-16 shrink-0 items-center justify-between sticky top-0 bg-gray-800 w-full px-3">
        <div className="flex items-center">
          <img onDoubleClick={() => setSocketDebug(!socketDebug) } className="h-10" src={logoLines} alt="Logo" />
          <FuseLogoText />
        </div>
        <Tooltip content="Start the Fuse tutorial!">
          <AcademicCapIcon className="h-6" onClick={() => window?.userGuiding?.previewGuide(99237)} />
        </Tooltip>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <div className="flex items-center justify-between p-2 sticky top-16 z-50 bg-gray-800">
              <div className="flex items-center space-x-1 text-md font-semibold my-3 text-gray-400">
                <Squares2X2Icon className="h-5 w-5" />
                <div>Spaces</div>
              </div>
              <Listbox>
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">Create a new Space or Channel</Listbox.Label>
                    <div className="relative">
                      <div className="inline-flex divide-x divide-black rounded-md shadow-sm">
                        <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-fuse-orange px-1.5 py-1 text-white shadow-sm">
                          <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          <p className="text-sm font-semibold">New</p>
                        </div>
                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-fuse-orange p-1 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                          <span className="sr-only">Create a new channel or Space</span>
                          <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </Listbox.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute right-0 z-[9999] mt-2 w-[15rem] origin-top-right divide-y divide-gray-100/50 overflow-hidden rounded-md bg-slate-800 shadow-lg ring-2 ring-white ring-opacity-25 focus:outline-none">
                          <Listbox.Option
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-sky-600 text-white' : 'text-white',
                                'cursor-default select-none p-4 text-sm'
                              )
                            }
                            onClick={() => {
                              toggleShowSidebar();
                              dispatch(showModal({modalId: MODAL_IDS.MODAL_NEW_BLANK_SPACE}));
                            }}
                            value="space"
                          >
                            {({ active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p className='font-semibold'>New Space</p>
                                </div>
                                <p className={classNames(active ? 'text-sky-200' : 'text-gray-500', 'mt-2')}>
                                  Create a new Space to better organize your channels
                                </p>
                              </div>
                            )}
                          </Listbox.Option>
                          <Listbox.Option
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-orange-600 text-white' : 'text-white',
                                'cursor-default select-none p-4 text-sm'
                              )
                            }
                            onClick={() => {
                              toggleShowSidebar();
                              setShowNewRoomModal(true);
                            }}
                          >
                            {({ active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p className='font-semibold'>New Channel</p>
                                </div>
                                <p className={classNames(active ? 'text-orange-200' : 'text-gray-500', 'mt-2')}>
                                  Create a new channel to communicate with people.
                                </p>
                              </div>
                            )}
                          </Listbox.Option>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <SortableTree id={"tree"} activeId={draggableActiveId} />
          </li>
          <li className="-mx-6 mt-auto sticky bottom-0 bg-gray-800 border-t border-gray-700 px-2">
            <div
              className="flex justify-between items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
            >
              <div className="flex items-center space-x-2">
                <img
                  className="h-8 w-8 rounded-full bg-gray-800"
                  src={user.avatar_url}
                  alt=""
                />
                <span className="sr-only">Your profile</span>
                <div>
                  <div aria-hidden="true" className="leading-4">{user.first_name} {user.last_name}</div>
                  <div aria-hidden="true" className="text-xs text-gray-400 leading-1">@{user.username}</div>
                </div>
              </div>
              <div>

              </div>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-slate-800 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-700">
                  <Cog6ToothIcon className="h-6 w-6 text-gray-300 hover:text-white transition cursor-pointer" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 bottom-12 z-10 mt-2 w-[15rem] origin-bottom-left divide-y divide-gray-100 rounded-md bg-gray-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            className={classNames(
                              active ? 'bg-gray-700' : 'text-white',
                              'group flex items-center px-4 py-2 text-sm text-white'
                            )}
                            onClick={() => setShowUserProfile(true)}
                          >
                            <PencilSquareIcon
                              className="mr-3 h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                            Edit Profile
                          </span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            className={classNames(
                              active ? 'bg-gray-700' : 'text-white',
                              'group flex items-center px-4 py-2 text-sm text-white'
                            )}
                            onClick={logout}
                          >
                            <ArrowLeftOnRectangleIcon
                              className="mr-3 h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                            Log Out
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <UserProfile open={showUserProfile} setOpen={setShowUserProfile} />
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default function Sidebar() {
  const {toggleShowSidebar, showSidebar} = useContext(DashboardContext);

  return (
    <>
      <Transition.Root show={showSidebar} as={Fragment}>
        <Dialog as="div" className="relative z-50 xl:hidden" onClose={toggleShowSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => toggleShowSidebar()}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SpacesStack />
                <ModalConfirmChannelAppend />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-30 xl:flex xl:w-[20%] xl:flex-col">
        <SpacesStack />
        <ModalConfirmChannelAppend />
      </div>
    </>
  )
}
