import {forwardRef, useContext, useState} from 'react';
import Channel from '../Channel';
import {useDndMonitor} from "@dnd-kit/core";
import {TvIcon} from "@heroicons/react/24/outline";
import DashboardContext from '../Contexts/DashboardContext';

const Item = forwardRef(({ id, isOverlay, attributes, listeners, withOpacity, over, isDragging, style, notDraggable, ...props }, ref) => {
  const {closeChannel} = useContext(DashboardContext);
  const [isOverTree, setIsOverTree] = useState(false);
  const [cannotDrop, setCannotDrop] = useState(true);
  const channel = props.channel;
  const inlineStyles = {
    opacity: withOpacity || isOverlay ? '0.8' : '1',
    ...style,
  };

  const [overMon, setOverMon] = useState(null);

  // When dragging, the channel will not be in props, so we have to go and find it in context
  // ,so we render the draggable channel
  const normalizedChannelId = parseInt(id.split(':').pop());

  useDndMonitor({
    onDragOver(event) {
      setIsOverTree(event.over?.data?.current?.type === 'TREE');
      setCannotDrop((event.over?.id?.toString()?.startsWith('tree:sub')));
      setOverMon(event.over);
    }
  });

  if (isOverlay && isOverTree && overMon) {
    inlineStyles.maxHeight = overMon.rect.height;
    inlineStyles.maxWidth = overMon.rect.width;
    inlineStyles.border = cannotDrop ? '2px solid red' : '2px solid green';

    return (
      <div style={inlineStyles} className="font-bold text-sm text-white bg-black bg-opacity-50 rounded-lg border-2 border-gray-700 p-2 flex items-center justify-between">
        <div className="flex space-x-2">
          {channel?.slug}
          <TvIcon className="h-4 w-4 ml-2" />
        </div>
      </div>
    );
  }

  if (isDragging && isOverlay && isNaN(normalizedChannelId)) {
    console.log('Normalized is Nan', id, props)
  }

  return (
    <div ref={ref} className={`${isOverlay && isDragging ? 'animate-wiggle' : ''}`} style={inlineStyles} {...props}>
      <Channel
        onClose={(channelId) => closeChannel(channelId)}
        isOverTree={isOverTree}
        over={over}
        handleProps={{attributes, listeners}}
        {...channel}
        channelId={normalizedChannelId}
        isOverlay={isOverlay}
        isDragging={isDragging}
        notDraggable={props.notDraggable}
        shrinkable={props.shrinkable}
      />
    </div>
  );
});

export default Item;
