export function getRgbString(colorJson) {
  colorJson ||= {r: 235, g: 179, b: 5}; // golden yellow
  return `rgb(${colorJson.r}, ${colorJson.g}, ${colorJson.b}, ${colorJson.a || 1})`;
}

export function generateRandomColor() {
  return {
    r: Math.random() * 255,
    g: Math.random() * 255,
    b: Math.random() * 255
  }
}
