import {createContext} from "react";

export default createContext({
  setShowNewRoomModal: () => {},
  appendChannelToActiveSpace: (channelId) => {},
  saveSpace: () => {},
  launchSpace: (spaceId) => {},
  changeSpaceName: (id, newName) => {},
  closeChannel: (id) => {},
  setActiveSpaceOrder: (orderedChannels) => {},
  setShowChannelSettingsModal: (channelId) => {},
  setShowArchiveChannelModal: (channelId) => {},
  activeSpace: {},
  draggableActiveId: null,
  showNewRoomModal: false,
});
