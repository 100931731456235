import {ArrowPathIcon, PencilIcon, PlusIcon, TagIcon, TvIcon} from "@heroicons/react/24/outline";
import {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ColorPicker, themes} from "react-pick-color";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useClickAway} from "react-use";
import ChannelTag from "../Channel/ChannelTag";
import useSocket from "../hooks/useSocket";
import {hideModal, MODAL_IDS} from "../reducers/modalsSlice";
import ChannelsContext from "../SortableChannelGrid/ChannelsContext";
import {generateRandomColor, getRgbString} from "../utils/colors";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

function TagNameInput({defaultValue, onChange}) {
  return (
    <input
      type="search"
      name="search"
      id="search"
      autoComplete="off"
      onChange={onChange}
      value={defaultValue}
      className="shrink bg-slate-700 px-2 w-full block rounded-md border-0 py-1 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
      placeholder="A great tag name..."
    />
  );
}

export function TagsManager() {
  const {channelsById} = useContext(ChannelsContext);
  const dismissColorPickerRef = useRef(null);
  const [showColorPicker, setColorPicker] = useState(false);
  const [tagColor, setTagColor] = useState(null);
  const [tagName, setTagName] = useState(null);
  const [tagIdToEdit, setTagIdToEdit] = useState(null);
  const [showAddExistingTag, setShowAddExistingTag] = useState(false);
  const [tagNameIsValid, setTagNameIsValid] = useState(true);
  const tagsById = useSelector(state => state.tags);
  const modals = useSelector(state => state.modals);
  const dispatch = useDispatch();
  const [socket] = useSocket();


  const {show: open = false, data} = modals[MODAL_IDS.MODAL_TAGS_MANAGER];
  const channel = channelsById[data.channelId];

  function onClose() {
    dispatch(hideModal(MODAL_IDS.MODAL_TAGS_MANAGER));
  }

  const tags = Object.values(tagsById);
  const channelTagsById = useMemo(() => {
    if (!channel) return;

    return channel.tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {});
  }, [channel?.tags]);

  useClickAway(dismissColorPickerRef, () => {
    setColorPicker(false);
  });

  useEffect(() => {
    if (!tagColor) setTagColor(generateRandomColor());
  }, []);

  useEffect(() => {
    setTagNameIsValid(!tagIdToEdit && tagName && tagName.length > 2);
  }, [tagName]);

  function clearTagValues() {
    setTagIdToEdit(null);
    setTagName('');
    setTagColor(generateRandomColor());
  }

  function removeTag(tag) {
    socket.emit('channels_users_tags:remove', {
      channelId: channel.id,
      tagId: tag.id,
      channelsUsersTagsId: tag.channels_users_tags_id
    }, ({success, msg}) => {
      if (!success) {
        toast.error(msg);
        return;
      }

      if (tagIdToEdit === tag.id) clearTagValues();

      const removedTag = tagsById[tag.id];
      toast.success(`"${removedTag.name}" has been removed from ${channel.slug}`, DEFAULT_TOAST_CONFIG);
    });
  }

  function addTag(tag) {
    socket.emit('channels_users_tags:add_tag', {channelId: channel.id, tag}, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG);
        return;
      }

      toast.success(`"${tag.name}" has been added to ${channel.slug}`, DEFAULT_TOAST_CONFIG);
      clearTagValues();
    });
  }

  function upsertTag() {
    if (tagIdToEdit) {
      socket.emit('channels_users_tags:edit_tag', {channelId: channel.id, tag: {tagId: tagIdToEdit, tagName, tagColor}}, ({success, msg}) => {
        if (success) {
          toast.success(`"${tagName}" has been edited`, DEFAULT_TOAST_CONFIG);
          clearTagValues();
          return;
        }

        toast.error(msg || 'The operation could not be completed.', DEFAULT_TOAST_CONFIG);
      });

      return;
    }

    socket.emit('channels_users_tags:create_tag', {channelId: channel.id, tag: {tagName, tagColor}}, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG);
        return;
      }

      toast.success(`"${tagName}" has been created and added to ${channel.slug}`, DEFAULT_TOAST_CONFIG);
      clearTagValues();
    });
  }

  function editTag({id: tagId}) {
    const tag = tagsById[tagId];
    setTagIdToEdit(tag.id);
    setTagName(tag.name);
    setTagColor(tag.color);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment} afterLeave={() => clearTagValues()}>
        <Dialog as="div" className="relative z-40 transition-all " onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg ring-2 ring-gray-200 bg-gray-800 backdrop-blur-md bg-opacity-75 pb-0 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                  <div className="mx-auto space-x-2 w-full flex items-center justify-center mb-5">
                    <TagIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                      Tags Manager
                    </Dialog.Title>
                  </div>
                  { channel ?
                    <>
                      <div className="space-x-2 flex items-center text-xs absolute top-2 left-2">
                        <TvIcon className="h-4 w-4" />
                        <span>{channel.slug}</span>
                      </div>
                      <div className="flex justify-between space-x-2 divide-x divide-gray-600 px-4 sm:p-6 sm:pb-5">
                        <div className="w-full flex flex-col justify-between">
                          {
                            showAddExistingTag ? (
                              <>
                                <div>
                                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ring-2 ring-white">
                                    <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                  </div>
                                  <div className="mt-3 sm:mt-5 text-center">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                                      Click to add existing tag
                                    </Dialog.Title>
                                  </div>
                                  <div className="flex flex-wrap gap-2 mt-5 font-semibold">
                                    {!tags.length ? (
                                      <>
                                        <p className="text-center w-full text-white/50 h-full mt-2 italic">No tags to add.</p>
                                        <p className="text-center w-full text-white/50 h-full italic">Try creating one? →</p>
                                      </>
                                    ) : null}
                                    {
                                      tags.map(tag => {
                                        if (!!channelTagsById[tag.id]) return null;

                                        return (
                                          <div className="cursor-pointer">
                                            <ChannelTag onClick={() => addTag(tag)} fill tagId={tag.id} key={`tags_manager_${tag.id}_${channel.id}`} />
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-start space-x-2 mt-7">
                                  <button
                                    type="button"
                                    className="mt-2 inline-flex rounded-md bg-transparent text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    onClick={() => {
                                      setShowAddExistingTag(false);
                                    }}
                                  >
                                    Back
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ring-2 ring-white">
                                    <TagIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                  </div>
                                  <div className="mt-3 sm:mt-5 text-center">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                                      Manage Tags
                                    </Dialog.Title>
                                  </div>
                                  <div className="flex flex-wrap gap-2 mt-5 font-semibold">
                                    {!channel.tags.length ? (
                                      <p className="text-center w-full text-white/50 h-full mt-5 italic">{channel.slug} has no tags</p>
                                    ) : null}
                                    {
                                      channel.tags.map(tag => {
                                        return (
                                          <ChannelTag
                                            onClick={() => editTag(tag)}
                                            onClickRemove={(e) => {
                                              e.stopPropagation();
                                              removeTag(tag);
                                            }}
                                            fill
                                            tagId={tag.id}
                                            key={`tags_manager_${tag.id}_${channel.id}`}
                                          />
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                                <div className="flex justify-start space-x-2 mt-7 mr-2">
                                  <button
                                    type="button"
                                    className="mt-2 inline-flex rounded-md bg-transparent text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    onClick={() => {
                                      setShowAddExistingTag(true);
                                    }}
                                  >
                                    Add Existing
                                  </button>
                                </div>
                              </>
                            )
                          }
                        </div>
                        <div className="w-full flex flex-col justify-between">
                          <div>
                            <div
                              className="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                              style={{backgroundColor: getRgbString(tagColor), color: getRgbString(tagColor)}}
                            >
                              {tagIdToEdit ? (
                                <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
                              ) : (
                                <TagIcon className="h-6 w-6 text-white" aria-hidden="true" />
                              )}
                            </div>
                            <div className="mt-3 text-center sm:mt-5 pl-5">
                              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                                {tagIdToEdit ? (
                                  <div className="flex items-center my-4 space-x-2 w-full justify-center">
                                    <span>Modify tag</span>
                                    <ChannelTag
                                      fill
                                      className="ring ring-white"
                                      onClickRemove={clearTagValues}
                                      tagId={tagIdToEdit}
                                      tagName={tagName}
                                      tagColor={tagColor}
                                    />
                                  </div>
                                ) : 'Create a new tag'}
                              </Dialog.Title>

                              <div className="mt-2 font-semibold space-y-4">
                                <div className="flex items-center">
                                  <div className="w-1/4 text-left">Name</div>
                                  <div className="w-full ml-4">
                                    <TagNameInput defaultValue={tagName} onChange={({target: {value}}) => setTagName(value)} />
                                  </div>
                                </div>
                                <div className="flex justify-between items-center relative">
                                  <div className="w-1/4 text-left">Color</div>
                                  <div className="w-full ml-4 mb-2">
                                    <div className="flex justify-end h-8 rounded-md border-inset border-2 border-white" style={{backgroundColor: getRgbString(tagColor)}} onClick={(ev) => {
                                      setColorPicker(true);
                                    }}>
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setTagColor(generateRandomColor())
                                    }}
                                    className="cursor-pointer text-xs text-right px-2 inline-flex flex-col justify-center items-center bg-white/75 text-gray-800"
                                  >
                                    <ArrowPathIcon className="h-4" />
                                  </span>
                                    </div>
                                  </div>
                                  {
                                    showColorPicker ? (
                                      <div className="absolute origin-left right-0 bottom-[2em] h-20" ref={dismissColorPickerRef}>
                                        <ColorPicker theme={themes.dark} hideAlpha hideInputs onChange={({rgb}) => setTagColor(rgb)} />
                                      </div>
                                    ) : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-end space-x-2">
                            <button
                              disabled={!tagNameIsValid && !tagIdToEdit}
                              type="button"
                              className="disabled:opacity-50 inline-flex rounded-md bg-transparent text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                              onClick={() => {
                                upsertTag();
                              }}
                            >
                              { tagIdToEdit ? 'Update Tag' : 'Add Tag' }
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
