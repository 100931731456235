import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://127.0.0.1:5173';

let socket;

export default function connect() {
  if (!!socket) return socket;

  socket = io(URL, {
    transports: ['websocket'],
  });

  socket.on('_unauthorized', () => {
    window.location.href = '/login';
  });

  return socket;
}
